import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

function ClaDoc(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo , handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{        
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.cladoc.title;            
                    if( confPantalla.cladoc.panminlvl == null || cookies.get('nivel') <= confPantalla.cladoc.panminlvl ){
                        if( respuesta == 1 ){
                            saveEstadoFormPrin(confPantalla.cladoc.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.cladoc.hijo.estadoForm)
                            saveTieneNivel(true);                            
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.cladoc.padre.urls);
                                    saveFormularioPrin(confPantalla.cladoc.padre.modelform);
                                    saveFormularioHijo(confPantalla.cladoc.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.cladoc.padre.urls, confPantalla.cladoc.hijo.urls, confPantalla.cladoc.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.cladoc.section.titulo } icono={ confPantalla.cladoc.section.icono } col={ confPantalla.cladoc.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.cladoc.section.wiki } >
                            <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ confPantalla.cladoc.padre.btnform.new }
                                        btnSave={ confPantalla.cladoc.padre.btnform.save }
                                        btnSaveAndNew={ confPantalla.cladoc.padre.btnform.saveandnew }
                                        btnDelete={ confPantalla.cladoc.padre.btnform.delete }
                                        btnClone={ confPantalla.cladoc.padre.btnform.clone }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.cladoc.padre.urls, confPantalla.cladoc.padre.modelform, confPantalla.cladoc.padre.tabla, confPantalla.cladoc.hijo.modelform, confPantalla.cladoc.title) }
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.cladoc.padre.modelform, true, confPantalla.cladoc.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.cladoc.padre.urls, confPantalla.cladoc.padre.modelform, confPantalla.cladoc.padre.tabla, confPantalla.cladoc.title) }
                            >         
                                <FormRow clases="" >
                                    {/* <MiSelect  
                                        estadoForm=  { estadoFormPrin }
                                        val=         { formularioPrin.pad  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.cladoc.padre.controles.pad.id }    
                                        name=        { confPantalla.cladoc.padre.controles.pad.name }   
                                        label=       { confPantalla.cladoc.padre.controles.pad.label }    
                                        placeholder= { confPantalla.cladoc.padre.controles.pad.placeholder }
                                        col=         { confPantalla.cladoc.padre.controles.pad.col } 
                                        isClearable= { confPantalla.cladoc.padre.controles.pad.isclearable }
                                        isSearchable={ confPantalla.cladoc.padre.controles.pad.issearchable }
                                        dataType=    { confPantalla.cladoc.padre.controles.pad.datatype }
                                        data=        { confPantalla.cladoc.padre.controles.pad.data }
                                        required=    { confPantalla.cladoc.padre.controles.pad.required } 
                                        disabled=    { confPantalla.cladoc.padre.controles.pad.disabled }
                                        readOnly =   { confPantalla.cladoc.padre.controles.pad.readonly }
                                        disablethisid={confPantalla.cladoc.padre.controles.pad.disablethisid}
                                        formulario = { formularioPrin } 
                                        change=      { handleSelectFormPrin }                                                    
                                    />                                                                      */}
                                    <FormInput                                                        
                                        estadoForm= { estadoFormPrin }
                                        value=      { formularioPrin.nom }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.cladoc.padre.controles.nom.id }   
                                        type=       { confPantalla.cladoc.padre.controles.nom.type }  
                                        name=       { confPantalla.cladoc.padre.controles.nom.name }   
                                        label=      { confPantalla.cladoc.padre.controles.nom.label }                                                       
                                        placeholder={ confPantalla.cladoc.padre.controles.nom.placeholder }    
                                        col=        { confPantalla.cladoc.padre.controles.nom.col } 
                                        required=   { confPantalla.cladoc.padre.controles.nom.required }
                                        readonly=   { confPantalla.cladoc.padre.controles.nom.readonly }                                                
                                        disabled=   { confPantalla.cladoc.padre.controles.nom.disabled }
                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                        onBlur=     {null} 
                                    /> 
                                </FormRow>                            
                            </Formulario>
                            <MiDataTable
                                formulario={formularioPrin} 
                                estadoForm= { estadoFormPrin }
                                id={"cladoc"}
                                columnas={confPantalla.cladoc.padre.datatable.columns}
                                data={registrosPrin}
                                buttons={confPantalla.cladoc.padre.datatable.buttons}
                                ordering={confPantalla.cladoc.padre.datatable.ordering}
                                order={confPantalla.cladoc.padre.datatable.order}
                                searching={confPantalla.cladoc.padre.datatable.searching}
                                paging={confPantalla.cladoc.padre.datatable.paging}
                                select={confPantalla.cladoc.padre.datatable.select}
                                pagelength={confPantalla.cladoc.padre.datatable.pagelength}
                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.cladoc.padre.urls, confPantalla.cladoc.hijo.urls, confPantalla.cladoc.padreshijos, confPantalla.cladoc.hijo.modelform, confPantalla.cladoc.padre.tabla) }
                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.cladoc.padre.urls, confPantalla.cladoc.padre.modelform, confPantalla.cladoc.padre.tabla, confPantalla.cladoc.title) }
                                saveLoadingTable={ saveLoadingTable }
                                loadingTable={loadingTable}
                                columnactions={confPantalla.cladoc.padre.datatable.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />                                                                                                             
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default ClaDoc