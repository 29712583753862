import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';


function CheckIos({val, name, label, label2, id, col, change, estadoForm, required, disabled, loading}) {
    

    useEffect(() => {
    
    }, [val]);

    return (
        <Fragment>
            {/* {
                val === "1"
                ?
                    <div className={"form-group col-md-"+col}>
                        <input id={id} className="apple-switch" type="checkbox" value={val}  name={name} onChange={ change !== null ? change : null  } checked />
                        <label htmlFor={id} className="ml-2" >{label}</label>
                    </div>
                :
                    val === "0"
                    ?
                        <div className={"form-group col-md-"+col}>
                            <input id={id} className="apple-switch" type="checkbox" value={val}  name={name} disabled />
                            <label htmlFor={id} className="ml-2" >{label}</label>
                        </div>
                    :
                        <div className={"form-group col-md-"+col}>
                            <input 
                                id={id} 
                                className="apple-switch" 
                                type="checkbox" 
                                value={val}  
                                name={name} 
                                onChange={ change !== null ? change : null  } 
                            />
                            <label htmlFor={id} className="ml-2" >{label}</label>
                        </div>
            } */}
            <div className={"form-group col-md-"+col}>
                    <label htmlFor={id} className="ml-2" style={{marginBottom: "0px"}}>{label2}</label>
               <div className={"form-group col-md-"+col}>
                    <input 
                        id={id} 
                        className="apple-switch" 
                        type="checkbox" 
                        value={val}  
                        name={name}
                        required={ required === undefined ? false : required }
                        disabled={ estadoForm === "disabled" || loading ? true : disabled === undefined ? false : disabled }
                        onChange={ change !== null ? change : null  }
                        checked={ val == 1 ? true : val == 0 ? false : null } 
                    />
                    <label htmlFor={id} className="ml-2" >{label}</label>
                </div>
            </div>

        </Fragment>
    );
}

export default CheckIos;