import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

import FormRow from '../FormRow';
import { Fragment } from 'react';
import MiSelect from '../MiSelect';
import ButtonOutline from '../../ui/ButtonOutline';
import FormInput from '../FormInput';
import { Spinner } from 'react-bootstrap';
import { alertService } from '../../../services/alertservice';

import { LoginContext } from '../../../hooks/context/LoginContext';


import { filtrosToWhere, createTxtFiltrosSearch, getApiUrl, axiosPost, MiReplaceAll } from '../../../helpers/helperApp';

function Informes(props){

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    
    const { select, formulario, getRegistro, estadoForm, loadReg, traduction, btnGenerar, btnfilubi  } = props; 
    
    const { logo } = useContext(LoginContext);

    const [ valSelect, saveValSelect ] = useState("");
    const [ variables, saveVariables ] = useState([]);
    const [ plantillas, savePlantillas ] = useState([]);
    const [ variablesData, saveVariablesData ] = useState({});
    const [ sql, saveSql ] = useState("");
    const [ loadInforme, saveLoadInforme ] = useState(false);
    const [ tipoInformeState, saveTipoInformeState ] = useState("");
    const [ nombreInformeState, saveNombreInformeState ] = useState("");
    const [ destinatariosState, saveDestinatariosState ] = useState("");

    const dataFiltros = [ 
        { "value" : "igual", "label" : "Igual" },
        { "value" : "distinto", "label" : "Distinto" },
        { "value" : "contiene", "label" : "Contiene" },
        { "value" : "nocontiene", "label" : "No contiene" },
        { "value" : "todos", "label" : "Todos" }
    ];

    const dataFiltrosNumFec = [ 
        { "value" : "=", "label" : "=" },
        { "value" : "<=", "label" : "<=" },
        { "value" : ">=", "label" : ">=" },
        { "value" : "<", "label" : "<" },
        { "value" : ">", "label" : ">" },
        { "value" : "!=", "label" : "!=" },
        { "value" : "todos", "label" : "Todos" }
    ];

    const cookies = new Cookies();
    const { apiKey } = useContext(LoginContext);


    
    useEffect( () => { 
        
        saveValSelect("");
        saveVariables([]);
        savePlantillas([]);
        saveVariablesData([]);
        
        return () =>{    
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
        }

    },[formulario.id])


    const handleSelect = (selectedItem, nameOfComponent) => {

        saveVariables([]);
        savePlantillas([]);

        if( selectedItem ){
            saveValSelect( selectedItem.value );
        }else{
            saveValSelect( "" );  
        }
    }

    const handleEjecutar = async () => {

        let hayVariables = false;
        let variasPlantillas = false;
        let preguntaDestinatarios = false;

        // Generamos el state para almacenar los datos seleccionados en las variables
        let formularioData = {};
        
        // Obtener los datos del informe para generar las variables si procede
        const data = new FormData();
        const validacion = apiKey();
        data.append("validacion", validacion);
        data.append("idinf", valSelect);
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+"/app/getDataInforme",data);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+"/app/getDataInforme", data, source.token);

        if( typeof respuesta.data == "string" ){
            // No pasó la validación en el api
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});
            window.location.href = "/";  
        }else{
            // Obtener el registro. Lo hacemos para cuando al informe solo le pasamos el id ya que lo ejecutamos desde un modal al hacer click en una tabla.
            let resForm;
            let dataGetRegistro;
            if( getRegistro ){
                const data2 = new FormData();
                data2.append("validacion",  apiKey());
                data2.append("id",  formulario.id);
                // resForm = await axios.post(getApiUrl(window.location.origin)+getRegistro,data2);
                resForm = await axiosPost(getApiUrl(window.location.origin)+getRegistro, data2, source2.token);
                dataGetRegistro = resForm.data[0];
            }
    
            saveSql(respuesta.data[0].sql);
            const tipoInforme = respuesta.data[0].tipo;
            saveTipoInformeState(tipoInforme);
            saveNombreInformeState(respuesta.data[0].nombre);
            const nombreInforme = respuesta.data[0].nombre;
            const destinatarios = respuesta.data[0].url;
            saveDestinatariosState(respuesta.data[0].url);
           
            // Variables
            if( respuesta.data[0].variables != "" && respuesta.data[0].variables != "[]" ){
                hayVariables = true;
                saveVariables(JSON.parse(respuesta.data[0].variables));
                
                for(let i = 0; i < JSON.parse(respuesta.data[0].variables).length; i++){
                    let id = JSON.parse(respuesta.data[0].variables)[i].idcon;
                    
                    // Colocamos el valor por defecto si procede
                    if( JSON.parse(respuesta.data[0].variables)[i].valdef != "" && getRegistro ){  
                        // Si estamos cargando los datos del registro porque ejecutamos el informe desde un modal de inicio utilizamos la variable local formularioState, que es donde almacenamos los datos del registro tras la llamada, para poner los valores por defecto en las variables, sino utilizamos la variables pasada en props normalmente
                        formularioData[i+"-"+id] = dataGetRegistro[JSON.parse(respuesta.data[0].variables)[i].valdef];                    
                    }else if( JSON.parse(respuesta.data[0].variables)[i].valdef != "" ){
                        formularioData[i+"-"+id] = formulario[JSON.parse(respuesta.data[0].variables)[i].valdef];
                    }else{
                        formularioData[i+"-"+id] = "";
                    }
    
                    // Colocamos el filtro por defecto si procede
                    if( JSON.parse(respuesta.data[0].variables)[i].condef != "" ){
                        formularioData[i+"-filtro-"+id] = JSON.parse(respuesta.data[0].variables)[i].condef;
                    }else{
                        formularioData[i+"-filtro-"+id] = "";
                    }
                }
            }
            
            // Plantillas        
            if( tipoInforme == "0" || tipoInforme == "3"){
                if( respuesta.data[0].idplantilla != null ){
                    // Tiene plantilla/s
                    formularioData["plantilla"] = "";
                    formularioData["destinatarios"] = respuesta.data[0].url;
                    if( formularioData["destinatarios"][0] == "*" ){
                        preguntaDestinatarios = true;
                    }
                    let plantillasAux = [];
                    if(  respuesta.data.length == 1 ){
                        formularioData["plantilla"] = respuesta.data[0].idplantilla;
                        // saveVariablesData(formularioData);
                        // plantillasAux.push({"value" : respuesta.data[0].idplantilla});
                        // savePlantillas(plantillasAux);
                    }else{
                        variasPlantillas = true;
                        // let plantillasAux = [];
                        for(let i = 0; i < respuesta.data.length; i++){                    
                                let aux = {"value" : respuesta.data[i].idplantilla, "label" : respuesta.data[i].plantilla};
                                plantillasAux.push(aux);
                        }                
                        // savePlantillas(plantillasAux);
                        // saveVariablesData(formularioData)
                    }
                    savePlantillas(plantillasAux);
                    saveVariablesData(formularioData)
                }
            }

    
            if( hayVariables ){
                saveVariablesData(formularioData);
            }else{
                // No hay variables enviamos los datos. El sql y la plantilla en caso de haberla
                if( !variasPlantillas && !preguntaDestinatarios){
                    crearInforme(respuesta.data[0].sql, formularioData["plantilla"], tipoInforme, nombreInforme, null, formularioData["destinatarios"]);        
                }
            }              

        }

    }

    const handleEjecutarVars = async () => {
        let where = filtrosToWhere(variablesData);
        const vars = document.querySelector(".containerVars");
        let filtrosSearch = createTxtFiltrosSearch(vars); 
        if( where == "" ){
            where = " true ";
        }
        // const sqlFinal = sql.replace("******FILTRO******", where);
        const sqlFinal = MiReplaceAll(sql, "******FILTRO******", where);
        
        const valido = checkVariables();

        if( valido ){
            crearInforme(sqlFinal, variablesData["plantilla"], tipoInformeState, nombreInformeState, filtrosSearch, variablesData["destinatarios"]);
        }
    }

    
    const crearInforme = (sql, plantilla, tipoInforme, nombreInforme, filtrosSearch, destinatarios) => {

        saveLoadInforme(true);
        localStorage.setItem("sql", sql);
        localStorage.setItem("idplantilla", plantilla);
        localStorage.setItem("valselect", valSelect);
        localStorage.setItem("tipoinforme", tipoInforme);
        localStorage.setItem("nombreinforme", nombreInforme);
        localStorage.setItem("formulario", JSON.stringify(formulario));
        localStorage.setItem("traduction", JSON.stringify(traduction));
        localStorage.setItem("logo", logo);
        if( filtrosSearch ){
            localStorage.setItem("filtrossearch", JSON.stringify(filtrosSearch));
        }else{
            localStorage.setItem("filtrossearch", "");
        }
        localStorage.setItem("destinatarios", destinatarios);

        window.open('/visorinformes', "_blank")
        saveLoadInforme(false);
    }

    const handleInput = (e) => {
        const { id, value } = e.target;
        saveVariablesData({ ...variablesData, [id]:value })      
    }

    const handleInputSelect = (e, id) => {

        if( e.value != "" ){
            saveVariablesData({ ...variablesData, [id]:e.value })  
        }else{
            saveVariablesData({ ...variablesData, [id]:"" })
        }

    }

    const checkVariables = () => {

        let valido = true;

        for (const [key, value] of Object.entries(variablesData)) {        
            let condicion = "";
            if( key != "plantilla" && key != "destinatarios" ){
                if( !key.includes("filtro") ){
                    let aux = key.split("-");
                    let idFiltro = `${aux[0]}-filtro-${aux[1]}`;
                    let idCampo = `${aux[0]}-${aux[1]}`;
                    let valFiltro = variablesData[`${aux[0]}-filtro-${aux[1]}`];
                    let valCampo = variablesData[`${aux[0]}-${aux[1]}`];

                    if( valFiltro == "" ){
                        // Filtro en blanco. Añadimos error al filtro y return false
                        const filtro = document.getElementById(idFiltro);
                        if( filtro ){
                            filtro.classList.add('errorinput')
                            return false;
                        }
                    }else{
                        if( valFiltro != "todos" ){
                            if( valCampo == "" ){
                                // Valor en blanco. Añadimos error y return false
                                const campo = document.getElementById(idCampo);
                                if( campo ){
                                    campo.classList.add('errorinput');
                                    return false;
                                }
                            }else{
                                const campo = document.getElementById(idCampo);
                                if( campo ){
                                    campo.classList.remove('errorinput');
                                }                                
                            }
                        }
                    }                    
                }
            }else{
                if( variablesData['destinatarios'] == "" ){
                    const control = document.getElementById('destinatarios');
                    if( control ){
                        control.classList.add('errorinput');
                        return false;
                    }                    
                }else if( variablesData['destinatarios'] != "" ){
                    const control = document.getElementById('destinatarios');
                    if( control ){
                        control.classList.remove('errorinput');
                    }
                }
                if( variablesData['plantilla'] == "" ){
                    const control = document.getElementById('plantilla');
                    if( control ){
                        control.classList.add('errorinput');
                        return false;
                    }                    
                }else if( variablesData['plantilla'] != "" ){
                    const control = document.getElementById('plantilla');
                    if( control ){
                        control.classList.remove('errorinput');
                    }
                }
            }   
        }

        return valido;
    }

    return(
        <Fragment>
            <FormRow>
                <MiSelect  
                    estadoForm=  { estadoForm }
                    val=         { valSelect }   
                    loading=     { loadReg || loadInforme ? true : false }
                    id=          { select.id }    
                    label=       { select.label }    
                    placeholder= { select.placeholder }
                    col=         { select.col } 
                    isClearable= { select.isclearable }
                    isSearchable={ select.issearchable }
                    dataType=    { select.datatype }
                    data=        { select.data }
                    data2=        { select.data2 }
                    required=    { select.required } 
                    disabled=    { select.disabled }
                    readOnly =   { select.readonly }
                    disablethisid={select.disablethisid}
                    formulario = { formulario } 
                    change=      { handleSelect }
                    selectinformes={true}
                    btnfilubi={btnfilubi}                                                    
                />
                <div className={"btnInforGen"}>
                    {
                        estadoForm != "readonly" && estadoForm != "disabled"
                        ?
                            <ButtonOutline 
                                clases={ btnfilubi ? "mt-3" : "mt-2" }
                                color={ "primary" }  
                                type="button" 
                                icono={ "" }   
                                id=""     
                                texto={ btnGenerar ? btnGenerar : traduction.informes.btn.title }   
                                accion={ handleEjecutar } 
                                disabled={ valSelect == "" || loadInforme ? true  : false}
                            />              
                        :
                            null
                    }
                </div>
            </FormRow>
                <div style={{ marginTop:5, marginBottom:10, textAlign: "center"}} >
                    {   loadInforme ? 
                        <Spinner animation="border" />
                        :
                        null
                    }                                
                </div>
            <FormRow>
                {   
                    variables.length > 0
                    ?
                        <div className="container bgVariables mt-2">
                               <hr data-content={traduction.informes.variables.title} className="hr-textgrey"></hr> 
                            <div className="row justify-content-md-center containerVars">
                            {
                                variables.map((variable, index, arr) => {
                                        if( variable.tipo == "txt" ||variable.tipo == "number" || variable.tipo == "datetime-local" ){
                                            return(
                                                <Fragment key={variable.idcon}>
                                                    <MiSelect  
                                                        val=         { variablesData[index+"-filtro-"+variable.idcon] }   
                                                        loading=     { false }
                                                        id=          { index+"-filtro-"+variable.idcon }    
                                                        label=       { variable.label }    
                                                        placeholder= { traduction.informes.filtro.title }
                                                        col=         { "4 filterVar" } 
                                                        isClearable= { false }
                                                        isSearchable={ true }
                                                        dataType=    { "codigo" }
                                                        data=        { variable.tipo == "number" || variable.tipo == "datetime-local" ? dataFiltrosNumFec: dataFiltros }                                                       
                                                        formulario = { variablesData } 
                                                        change=      { e => { handleInputSelect(e, index+"-filtro-"+variable.idcon) } }                                                    
                                                    />
                                                    {
                                                        variablesData[index+"-filtro-"+variable.idcon] != "todos" && variablesData[index+"-filtro-"+variable.idcon] != "all"
                                                        ?
                                                            <FormInput 
                                                                estadoForm= { "" }
                                                                value=      { variablesData[index+"-"+variable.idcon] }
                                                                loading=    { false }
                                                                id=         { index+"-"+variable.idcon }   
                                                                type=       { variable.tipo }  
                                                                label=      { "" }                                                       
                                                                placeholder={ variable.label }    
                                                                col=        { "8 mt-2 filterVal " } 
                                                                change=     { handleInput }
                                                            />
                                                        :
                                                            <div className="col-8"></div>
                                                    }
                                                </Fragment>
                                            )
                                        }
                                        if( variable.tipo == "cbo" ){
                                            return(
                                                <Fragment key={variable.idcon}>
                                                    <MiSelect  
                                                        val=         { variablesData[index+"-filtro-"+variable.idcon] }   
                                                        loading=     { false }
                                                        id=          { index+"-filtro-"+variable.idcon }    
                                                        label=       { variable.label }    
                                                        placeholder= { traduction.informes.filtro.title }
                                                        col=         { "4 filterVar" } 
                                                        isClearable= { false }
                                                        isSearchable={ true }
                                                        dataType=    { "codigo" }
                                                        data=        { dataFiltros }                                                       
                                                        formulario = { variablesData } 
                                                        change=      { e => { handleInputSelect(e, index+"-filtro-"+variable.idcon) } }                                                    
                                                    />
                                                    {
                                                        variablesData[index+"-filtro-"+variable.idcon] != "todos" && variablesData[index+"-filtro-"+variable.idcon] != "all"
                                                        ?
                                                            <MiSelect  
                                                                val=         { variablesData[index+"-"+variable.idcon] }   
                                                                loading=     { false }
                                                                id=          { index+"-"+variable.idcon }    
                                                                label=       { "" }    
                                                                placeholder= { variable.label }
                                                                col=         { "8 mt-2 filterVal pt24px" } 
                                                                isClearable= { false }
                                                                isSearchable={ true }
                                                                dataType=    { variable.origendatos }
                                                                data=        { variable.datos }                                                        
                                                                formulario = { variablesData } 
                                                                change=      { e => { handleInputSelect(e, index+"-"+variable.idcon) } }                                                    
                                                            />
                                                        :
                                                            <div className="col-8"></div>
                                                    }
                                                </Fragment>
                                            )
                                        }
                                })
                            }
                            </div>
                                {
                                    (destinatariosState[0] === "*" || destinatariosState[0] === "") && tipoInformeState == 3
                                    ?
                                        <Fragment>
                                            <hr data-content={traduction.informes.destinatariossection.title} className="hr-textgrey"></hr> 
                                            <div className="row justify-content-md-center">
                                                <FormInput 
                                                    estadoForm= { "" }
                                                    value=      { variablesData["destinatarios"] ? MiReplaceAll(variablesData["destinatarios"], "*", "") : "" }
                                                    loading=    { false }
                                                    id=         { "destinatarios" }   
                                                    type=       { "text" }  
                                                    label=      { traduction.informes.destinatarios.title }                                                       
                                                    placeholder={ traduction.informes.destinatarios.title }    
                                                    col=        { "12" } 
                                                    change=     { handleInput }
                                                />
                                            </div>
                                        </Fragment>
                                    :
                                        null
                                }
                                {
                                    plantillas.length > 0
                                        ?
                                            <Fragment>
                                                <hr data-content={traduction.informes.plantillas.title} className="hr-textgrey"></hr> 
                                                <div className="row justify-content-md-center">
                                                    <MiSelect  
                                                    val=         { variablesData["plantilla"] }   
                                                    loading=     { false }
                                                    id=          { "plantilla" }    
                                                    label=       {traduction.informes.plantillas.title}   
                                                    placeholder= {traduction.informes.plantillas.title}
                                                    col=         { "12" } 
                                                    isClearable= { false }
                                                    isSearchable={ true }
                                                    dataType=    { "codigo" }
                                                    data=        { plantillas }                                                       
                                                    formulario = { formulario } 
                                                    change=      { e => { handleInputSelect(e, "plantilla") } }                                                    
                                                />                                            
                                                </div>
                                            </Fragment>
                                        :
                                        null
                                }
                                    
                            <FormRow>
                                <ButtonOutline 
                                    clases={ "col-12 mt-2" }
                                    color={ "primary" }  
                                    type="button" 
                                    icono={ "" }   
                                    id=""     
                                    texto={ traduction.informes.btn.title }   
                                    accion={ handleEjecutarVars } 
                                    disabled={ loadInforme ? true : false }
                                />      
                            </FormRow>
                        </div>
                    :
                    plantillas.length > 0 && variables.length == 0
                        ?
                        <div className="container bgVariables mt-2">
                            {
                                (destinatariosState[0] === "*" || destinatariosState[0] === "") && tipoInformeState == 3
                                ?
                                    <Fragment>
                                        <hr data-content={traduction.informes.destinatariossection.title} className="hr-textgrey"></hr> 
                                        <div className="row justify-content-md-center">
                                            <FormInput 
                                                estadoForm= { "" }
                                                value=      { variablesData["destinatarios"] ? MiReplaceAll(variablesData["destinatarios"], "*", "") : "" }
                                                loading=    { false }
                                                id=         { "destinatarios" }   
                                                type=       { "text" }  
                                                label=      { traduction.informes.destinatarios.title }                                                       
                                                placeholder={ traduction.informes.destinatarios.title }    
                                                col=        { "12" } 
                                                change=     { handleInput }
                                            />
                                        </div>
                                    </Fragment>
                                :
                                    null
                            }
                            <hr data-content={traduction.informes.plantillas.title} className="hr-textgrey"></hr>  
                            <div className="row justify-content-md-center">
                                <MiSelect  
                                    val=         { variablesData["plantilla"] }   
                                    loading=     { false }
                                    id=          { "plantilla" }    
                                    label=       {traduction.informes.plantillas.title}   
                                    placeholder= {traduction.informes.plantillas.title}
                                    col=         { "12" } 
                                    isClearable= { false }
                                    isSearchable={ true }
                                    dataType=    { "codigo" }
                                    data=        { plantillas }                                                       
                                    formulario = { formulario } 
                                    change=      { e => { handleInputSelect(e, "plantilla") } }                                                    
                                />    
                            </div>
                            <FormRow>
                                <ButtonOutline 
                                    clases={ "col-12 mt-2" }
                                    color={ "primary" }  
                                    type="button" 
                                    icono={ "" }   
                                    id=""     
                                    texto={ traduction.informes.btn.title }   
                                    accion={ handleEjecutarVars } 
                                    disabled={ loadInforme ? true : false }
                                />      
                            </FormRow>
                        </div>
                    :
                    // No tiene variables y tiene 1 plantilla pero pregunta los destinatarios
                    (destinatariosState[0] === "*" || destinatariosState[0] === "") && tipoInformeState == 3  && variables.length == 0
                    ?
                        <div className="container bgVariables mt-2">
                            <hr data-content={traduction.informes.destinatariossection.title} className="hr-textgrey"></hr> 
                            <div className="row justify-content-md-center">
                                <FormInput 
                                    estadoForm= { "" }
                                    value=      { variablesData["destinatarios"] ? MiReplaceAll(variablesData["destinatarios"], "*", "") : "" }
                                    loading=    { false }
                                    id=         { "destinatarios" }   
                                    type=       { "text" }  
                                    label=      { traduction.informes.destinatarios.title }                                                       
                                    placeholder={ traduction.informes.destinatarios.title }    
                                    col=        { "12" } 
                                    change=     { handleInput }
                                />
                            <FormRow>
                                <ButtonOutline 
                                    clases={ "col-12 mt-2" }
                                    color={ "primary" }  
                                    type="button" 
                                    icono={ "" }   
                                    id=""     
                                    texto={ traduction.informes.btn.title }   
                                    accion={ handleEjecutarVars } 
                                    disabled={ loadInforme ? true : false }
                                />      
                            </FormRow>
                            </div>
                        </div>                        
                    :
                        null

                }
            </FormRow>
        </Fragment>
    )
}

export default Informes;