import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import TextArea from '../../componentes/formulario/TextArea';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import MiSelect from '../../componentes/formulario/MiSelect';
import { Alert } from '../../componentes/ui/Alert';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

function Paises(props){

    const cookies = new Cookies();

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin } = useLogicForm();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{          
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.paises.title;          
                    if( confPantalla.paises.panminlvl == null || cookies.get('nivel') <= confPantalla.paises.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveEstadoFormPrin(confPantalla.paises.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.paises.hijo.estadoform);                             
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.paises.padre.urls);
                                    saveFormularioPrin(confPantalla.paises.padre.modelform);
                                    saveFormularioHijo(confPantalla.paises.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.paises.padre.urls, confPantalla.paises.hijo.urls, confPantalla.paises.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 
        
    },[confPantalla])
    


 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.paises.section.titulo } icono={ confPantalla.paises.section.icono } col={ confPantalla.paises.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.paises.section.wiki } >
                                        <Formulario 
                                                    estadoForm= { estadoFormPrin }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.paises.padre.btnform.new }
                                                    btnSave={ confPantalla.paises.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.paises.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.paises.padre.btnform.delete }
                                                    btnClone={ confPantalla.paises.padre.btnform.clone }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.paises.padre.urls, confPantalla.paises.padre.modelform, confPantalla.paises.padre.tabla, confPantalla.paises.hijo.modelform, confPantalla.paises.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.paises.padre.modelform, true, confPantalla.paises.padre.tabla) }  
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.paises.padre.urls, confPantalla.paises.padre.modelform, confPantalla.paises.padre.tabla, confPantalla.paises.title) } 
                                        >                                        
                                                    <FormRow clases="" >
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.cod }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paises.padre.controles.cod.id }   
                                                            type=       { confPantalla.paises.padre.controles.cod.type }  
                                                            name=       { confPantalla.paises.padre.controles.cod.name }   
                                                            label=      { confPantalla.paises.padre.controles.cod.label }                                                       
                                                            placeholder={ confPantalla.paises.padre.controles.cod.placeholder }    
                                                            col=        { confPantalla.paises.padre.controles.cod.col } 
                                                            required=   { confPantalla.paises.padre.controles.cod.required }
                                                            readonly=   { confPantalla.paises.padre.controles.cod.readonly }                                                
                                                            disabled=   { confPantalla.paises.padre.controles.cod.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                        <FormInput                                                        
                                                            estadoForm= { estadoFormPrin }
                                                            value=      { formularioPrin.nom }
                                                            loading=    { loadReg ? true : false }
                                                            id=         { confPantalla.paises.padre.controles.nom.id }   
                                                            type=       { confPantalla.paises.padre.controles.nom.type }  
                                                            name=       { confPantalla.paises.padre.controles.nom.name }   
                                                            label=      { confPantalla.paises.padre.controles.nom.label }                                                       
                                                            placeholder={ confPantalla.paises.padre.controles.nom.placeholder }    
                                                            col=        { confPantalla.paises.padre.controles.nom.col } 
                                                            required=   { confPantalla.paises.padre.controles.nom.required }
                                                            readonly=   { confPantalla.paises.padre.controles.nom.readonly }                                                
                                                            disabled=   { confPantalla.paises.padre.controles.nom.disabled }
                                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                            onBlur=     {null} 
                                                        />
                                                    </FormRow>                                                       
                                        </Formulario>
                                        <MiDataTable 
                                            formulario={formularioPrin}
                                            estadoForm= { estadoFormPrin }
                                            id={"paises"}
                                            columnas={confPantalla.paises.padre.datatable.columns}
                                            data={registrosPrin}
                                            buttons={confPantalla.paises.padre.datatable.buttons}
                                            ordering={confPantalla.paises.padre.datatable.ordering}
                                            order={confPantalla.paises.padre.datatable.order}
                                            searching={confPantalla.paises.padre.datatable.searching}
                                            paging={confPantalla.paises.padre.datatable.paging}
                                            select={confPantalla.paises.padre.datatable.select}
                                            pagelength={confPantalla.paises.padre.datatable.pagelength}
                                            handleEditReg= { e => handleEditRegPrin(e, confPantalla.paises.padre.urls, confPantalla.paises.hijo.urls, confPantalla.paises.padreshijos, confPantalla.paises.hijo.modelform, confPantalla.paises.padre.tabla) }
                                            handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.paises.padre.urls, confPantalla.paises.padre.modelform, confPantalla.paises.padre.tabla, confPantalla.paises.title) }
                                            saveLoadingTable={ saveLoadingTable }
                                            loadingTable={loadingTable}
                                            columnactions={confPantalla.paises.padre.datatable.columnactions.title}
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />                                             
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Paises