import React, { useEffect, useContext, useState, Fragment } from 'react';
import axios from 'axios';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Kpi from '../../componentes/ui/Kpi';
import Section from '../../componentes/ui/Section';
import { Alert } from '../../componentes/ui/Alert';
import { Accordion, Card } from "react-bootstrap";
import MiDataTable from '../../componentes/ui/MiDataTable';
import CalendarioYear from '../../componentes/ui/CalendarioYear';
import swal from 'sweetalert';
import { alertService } from '../../services/alertservice';


function CheckListTrab(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ loadingTable, saveLoadingTable] = useState(true);
    
    const [ datosTableHojas, saveDatosTableHojas ] = useState([]);
    const [ valCalendarHojas, saveValCalendarHojas ] = useState([
        {
            id: "",
            name: "", 
            startDate : "", 
            endDate : "", 
        }
    ]); 
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);
    const [ acordeonCalPlantillas, saveAcordeonCalPlantillas ] = useState(null);
    const [ acordeonCalHojas, saveAcordeonCalHojas ] = useState(null);  


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.maintenance.title+" - "+confPantalla.checklisttrab.title;             
                    saveLoadedConf(true);  
                    if( cookies.get('defaultActiveKeyCheckList') ){
                        toogleAcordeon(cookies.get('defaultActiveKeyCheckList'), "acordeonPrin")
                    }      
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                    cookies.set('defaultActiveKeyCheckList', null, {path: '/'});
                }else{
                    saveAcordeonPrin(id);
                    cookies.set('defaultActiveKeyCheckList', id, {path: '/'});
                }                
                break;
            case "acordeonCalPlantillas":
                if( acordeonCalPlantillas == id ){
                    saveAcordeonCalPlantillas(null);
                }else{
                    saveAcordeonCalPlantillas(id);
                }                
                break;
            case "acordeonCalHojas":
                if( acordeonCalHojas == id ){
                    saveAcordeonCalHojas(null);
                }else{
                    saveAcordeonCalHojas(id);
                }                
                break;
        }
    }

    const clickHoja = (e, rowData) => {

        let style, id, id_tra, descripcion;

        if( rowData ){
            style = rowData.style;
            id = rowData.id;
            id_tra = rowData.id_tra;
            descripcion = rowData.descripcion;
        }else{
            style = e.target.dataset.style;
            id = e.target.dataset.iddoc;
            id_tra = e.target.dataset.id_tra;
            descripcion = e.target.dataset.descripcion;
        }
  
        switch (style) {
            case "alert-primary":
                    // Pregunta trabajador: Iniciar Hoja
                    swal({
                        title: confPantalla.checklisttrab.controles.hojas.iniciar.swal.title,
                        text: descripcion,                      
                        icon: "warning",
                        buttons: {
                            cancel : {text: confPantalla.checklisttrab.controles.hojas.iniciar.swal.btncancel.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                            confirm : {text: confPantalla.checklisttrab.controles.hojas.iniciar.swal.btnconfirm.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                            
                        } 
                    }).then(confirmacion => {
                        if( confirmacion == true ){
                            if( id_tra != "0" ){
                                // Si tiene trabajo lanzamos el asistente                                
                                props.history.push("/checklistwiz/"+id); 
                            }                           
                        }
                    })
                break
            case "alert-danger":
            case "alert-success":
                // DANGER CLICK -> NADA DE NADA(MENSAJE "NO se puede EDITAR esta HOJA")
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.checklisttrab.controles.hojas.revision.title}`, {autoClose: true});
            break
        }        
    }

    return(
        <div className="App wrapper">
                <Alert fondo={true} />    
                {
                    confPantalla !== undefined
                    ?
                        <Fragment>
                            <Menusup 
                                props = { props }
                            />
                            <Sidebar 
                                props = { props }
                            />
                        </Fragment>
                    :
                    null
                } 
            <div className="content-wrapper">
                <div className="content-header"></div>
                <section className="content">
                        {
                            loadedConf === true && confPantalla !== undefined
                            ?
                                <div>
                                    <Kpi
                                        conf={ confPantalla.checklisttrab.controles.kpi }
                                        getDataTable1 = { confPantalla.checklisttrab.controles.hojas.datatable.getdata }
                                        saveCalendar1 = { saveValCalendarHojas }
                                        saveDataTable1 = { saveDatosTableHojas }
                                        saveLoadingTable = { saveLoadingTable }
                                    />
                                        <Accordion defaultActiveKey={ cookies.get('defaultActiveKeyCheckList') ? cookies.get('defaultActiveKeyCheckList') : ""  }  >                                    
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => toogleAcordeon('1', "acordeonPrin")} className={ acordeonPrin == "1" ? "activeAcordeon" : "" } >
                                                <i className={ confPantalla.checklisttrab.controles.hojas.section.icono } ></i> { confPantalla.checklisttrab.controles.hojas.section.titulo }
                                                {
                                                        acordeonPrin == "1"
                                                        ?
                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                        :
                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="1" className={"slowAcordeon"}>
                                                    <Card.Body>
                                                            {
                                                                datosTableHojas != null
                                                                ?
                                                                    <Fragment>
                                                                        <div className="row" >
                                                                            <div className="col-12">                                                                                
                                                                            <MiDataTable 
                                                                                estadoForm= { "" }
                                                                                formulario={ {id:0} }
                                                                                id={"hojaschecklisttrab"}
                                                                                columnas={confPantalla.checklisttrab.controles.hojas.datatable.columns}
                                                                                data={datosTableHojas}
                                                                                linkColumns={confPantalla.checklisttrab.controles.hojas.datatable.linkColumns}
                                                                                buttons={confPantalla.checklisttrab.controles.hojas.datatable.buttons}
                                                                                ordering={confPantalla.checklisttrab.controles.hojas.datatable.ordering}
                                                                                order={confPantalla.checklisttrab.controles.hojas.datatable.order}
                                                                                searching={confPantalla.checklisttrab.controles.hojas.datatable.searching}
                                                                                paging={confPantalla.checklisttrab.controles.hojas.datatable.paging}
                                                                                select={confPantalla.checklisttrab.controles.hojas.datatable.select}
                                                                                pagelength={confPantalla.checklisttrab.controles.hojas.datatable.pagelength}
                                                                                saveLoadingTable={ saveLoadingTable }
                                                                                loadingTable={loadingTable}
                                                                                columnactions={confPantalla.checklisttrab.controles.hojas.datatable.columnactions.title}
                                                                                traduction={confPantalla.config.trad.components.datatable}
                                                                                functionClickTd={ clickHoja }
                                                                            />                          

                                                                            </div>
                                                                            <div className="col-12 calYearEnc" style={{marginTop: "15px"}}>
                                                                                <Accordion /*defaultActiveKey="0"*/ >
                                                                                    <Card>
                                                                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonCalHojas")}  className={ acordeonCalHojas == "0" ? "activeAcordeon" : "" } >
                                                                                            <i className={ confPantalla.checklisttrab.controles.hojas.calendario.acordeon.icono } ></i> { confPantalla.checklisttrab.controles.hojas.calendario.acordeon.title }
                                                                                            {
                                                                                                acordeonCalHojas == "0"
                                                                                                ?
                                                                                                    <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                                                                :
                                                                                                    <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                                                            }                                                                  
                                                                                        </Accordion.Toggle>
                                                                                    </Card>
                                                                                    <Accordion.Collapse eventKey="0" className={"slowAcordeon"}>
                                                                                        <Card.Body>
                                                                                            <CalendarioYear 
                                                                                                data = { valCalendarHojas }
                                                                                                locale = { cookies.get('idioma') }
                                                                                                onDayClick={ true }
                                                                                                colorTitleEvent={ true }
                                                                                                functionClickTitle = { clickHoja }
                                                                                            />
                                                                                        </Card.Body>
                                                                                    </Accordion.Collapse>
                                                                                </Accordion>
                                                                            </div>                                                    
                                                                        </div>
                                                                    </Fragment>
                                                                :
                                                                    null
                                                            }
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>                       
                                </div>                             
                            :
                                <LoadingPage />
                        }
                </section>
            </div>
            <Footer />        
        </div>
    );
}

export default CheckListTrab