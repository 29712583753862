import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import ListaRel from '../../componentes/formulario/ListaRel/ListaRel';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

function TipAdj(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo , handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{   
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.tipadj.title;                 
                    if( confPantalla.tipadj.panminlvl == null || cookies.get('nivel') <= confPantalla.tipadj.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveEstadoFormPrin(confPantalla.tipadj.padre.estadoForm);
                            saveEstadoFormHijo(confPantalla.tipadj.hijo.estadoform)                             
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.tipadj.padre.urls);
                                    saveFormularioPrin(confPantalla.tipadj.padre.modelform);
                                    saveFormularioHijo(confPantalla.tipadj.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.tipadj.padre.urls, confPantalla.tipadj.hijo.urls, confPantalla.tipadj.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])
 

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>    
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section titulo={ confPantalla.tipadj.section.titulo } icono={ confPantalla.tipadj.section.icono } col={ confPantalla.tipadj.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.tipadj.section.wiki } >
                            <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ confPantalla.tipadj.padre.btnform.new }
                                        btnSave={ confPantalla.tipadj.padre.btnform.save }
                                        btnSaveAndNew={ confPantalla.tipadj.padre.btnform.saveandnew }
                                        btnDelete={ confPantalla.tipadj.padre.btnform.delete }
                                        btnClone={ confPantalla.tipadj.padre.btnform.clone }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.tipadj.padre.urls, confPantalla.tipadj.padre.modelform, confPantalla.tipadj.padre.tabla, confPantalla.tipadj.hijo.modelform, confPantalla.tipadj.title) } 
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.tipadj.padre.modelform, true, confPantalla.tipadj.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.tipadj.padre.urls, confPantalla.tipadj.padre.modelform, confPantalla.tipadj.padre.tabla, confPantalla.tipadj.title) } 
                            >         
                            <h4>{ formularioPrin.ruta }</h4>
                            <Tabs defaultActiveKey="tab1" id="tab1">
                                <Tab eventKey="tab1" title={ confPantalla.tipadj.tabs.tab1.titulo }>
                                    <FormRow clases="" >
                                        <MiSelect  
                                            estadoForm=  { estadoFormPrin }
                                            val=         { formularioPrin.pad  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.tipadj.padre.controles.pad.id }    
                                            name=        { confPantalla.tipadj.padre.controles.pad.name }   
                                            label=       { confPantalla.tipadj.padre.controles.pad.label }    
                                            placeholder= { confPantalla.tipadj.padre.controles.pad.placeholder }
                                            col=         { confPantalla.tipadj.padre.controles.pad.col } 
                                            isClearable= { confPantalla.tipadj.padre.controles.pad.isclearable }
                                            isSearchable={ confPantalla.tipadj.padre.controles.pad.issearchable }
                                            dataType=    { confPantalla.tipadj.padre.controles.pad.datatype }
                                            data=        { confPantalla.tipadj.padre.controles.pad.data }
                                            required=    { confPantalla.tipadj.padre.controles.pad.required } 
                                            disabled=    { confPantalla.tipadj.padre.controles.pad.disabled }
                                            readOnly =   { confPantalla.tipadj.padre.controles.pad.readonly }
                                            disablethisid={confPantalla.tipadj.padre.controles.pad.disablethisid}
                                            formulario = { formularioPrin } 
                                            change=      { handleSelectFormPrin }                                                    
                                        />                                    
                                        <FormInput                                                        
                                            estadoForm= { estadoFormPrin }
                                            value=      { formularioPrin.nom }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.tipadj.padre.controles.nom.id }   
                                            type=       { confPantalla.tipadj.padre.controles.nom.type }  
                                            name=       { confPantalla.tipadj.padre.controles.nom.name }   
                                            label=      { confPantalla.tipadj.padre.controles.nom.label }                                                       
                                            placeholder={ confPantalla.tipadj.padre.controles.nom.placeholder }    
                                            col=        { confPantalla.tipadj.padre.controles.nom.col } 
                                            required=   { confPantalla.tipadj.padre.controles.nom.required }
                                            readonly=   { confPantalla.tipadj.padre.controles.nom.readonly }                                                
                                            disabled=   { confPantalla.tipadj.padre.controles.nom.disabled }
                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                            onBlur=     {null} 
                                        /> 
                                        <FormInput                                                        
                                            estadoForm= { estadoFormPrin }
                                            value=      { formularioPrin.nom_en }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.tipadj.padre.controles.nom_en.id }   
                                            type=       { confPantalla.tipadj.padre.controles.nom_en.type }  
                                            name=       { confPantalla.tipadj.padre.controles.nom_en.name }   
                                            label=      { confPantalla.tipadj.padre.controles.nom_en.label }                                                       
                                            placeholder={ confPantalla.tipadj.padre.controles.nom_en.placeholder }    
                                            col=        { confPantalla.tipadj.padre.controles.nom_en.col } 
                                            required=   { confPantalla.tipadj.padre.controles.nom_en.required }
                                            readonly=   { confPantalla.tipadj.padre.controles.nom_en.readonly }                                                
                                            disabled=   { confPantalla.tipadj.padre.controles.nom_en.disabled }
                                            change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                            onBlur=     {null} 
                                        /> 
                                    </FormRow>
                                </Tab>
                                <Tab eventKey="tab2" title={ confPantalla.tipadj.tabs.tab2.titulo }>
                                    <FormRow>
                                        <ListaRel 
                                            estadoForm=  { estadoFormPrin }
                                            val=         { formularioPrin.rel_tipadj_usu  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.id }    
                                            name=        { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.name }   
                                            label=       { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.label }    
                                            placeholder= { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.placeholder }
                                            col=         { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.col } 
                                            isClearable= { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.isclearable }
                                            isSearchable={ confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.issearchable }
                                            dataType=    { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.datatype }
                                            data=        { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.data }
                                            required=    { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.required } 
                                            disabled=  { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.disabled }
                                            readOnly =   { confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.readonly }
                                            disablethisid={ confPantalla.tipadj.padre.controles.rel_tipadj_usu.select.disablethisid }
                                            formulario = { formularioPrin }
                                            saveFormulario = { saveFormularioPrin }
                                            table = { confPantalla.tipadj.padre.controles.rel_tipadj_usu.table }
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />
                                    </FormRow>
                                </Tab>
                                <Tab eventKey="tab3" title={ confPantalla.tipadj.tabs.tab3.titulo }>
                                    <FormRow>
                                        <ListaRel 
                                            estadoForm=  { estadoFormPrin }
                                            val=         { formularioPrin.rel_tipadj_nvl  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.id }    
                                            name=        { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.name }   
                                            label=       { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.label }    
                                            placeholder= { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.placeholder }
                                            col=         { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.col } 
                                            isClearable= { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.isclearable }
                                            isSearchable={ confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.issearchable }
                                            dataType=    { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.datatype }
                                            data=        { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.data }
                                            required=    { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.required } 
                                            disabled=  { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.disabled }
                                            readOnly =   { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.readonly }
                                            disablethisid={ confPantalla.tipadj.padre.controles.rel_tipadj_nvl.select.disablethisid }
                                            formulario = { formularioPrin }
                                            saveFormulario = { saveFormularioPrin }
                                            table = { confPantalla.tipadj.padre.controles.rel_tipadj_nvl.table }
                                            traduction={confPantalla.config.trad.components.datatable}
                                        />
                                    </FormRow>                             
                                </Tab>
                            </Tabs>   
                            </Formulario>                            
                            <MiDataTable
                                formulario={formularioPrin} 
                                estadoForm= { estadoFormPrin }
                                id={"tipadj"}
                                columnas={confPantalla.tipadj.padre.datatable.columns}
                                data={registrosPrin}
                                buttons={confPantalla.tipadj.padre.datatable.buttons}
                                ordering={confPantalla.tipadj.padre.datatable.ordering}
                                order={confPantalla.tipadj.padre.datatable.order}
                                searching={confPantalla.tipadj.padre.datatable.searching}
                                paging={confPantalla.tipadj.padre.datatable.paging}
                                select={confPantalla.tipadj.padre.datatable.select}
                                pagelength={confPantalla.tipadj.padre.datatable.pagelength}
                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.tipadj.padre.urls, confPantalla.tipadj.hijo.urls, confPantalla.tipadj.padreshijos, confPantalla.tipadj.hijo.modelform, confPantalla.tipadj.padre.tabla) }
                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.tipadj.padre.urls, confPantalla.tipadj.padre.modelform, confPantalla.tipadj.padre.tabla, confPantalla.tipadj.title) }
                                saveLoadingTable={ saveLoadingTable }
                                loadingTable={loadingTable}
                                columnactions={confPantalla.tipadj.padre.datatable.columnactions.title}
                                traduction={confPantalla.config.trad.components.datatable}
                            />                                                                                                             
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default TipAdj