import React, { useEffect, useContext, useState, Fragment } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Cookies from 'universal-cookie';

import LoadingPage from '../../componentes/ui/LoadingPage';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import UplmInfo from '../../componentes/formulario/UplmInfo/UplmInfo';
import UplmInfoSearch from '../../componentes/formulario/UplmInfoSearch/UplmInfoSearch';
import Informes from '../../componentes/formulario/informes/Informes';
import { Alert } from '../../componentes/ui/Alert';
import { SRLWrapper } from 'simple-react-lightbox';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import { Accordion, Card } from "react-bootstrap";




function CentrosAdj(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ filterTable, saveFilterTable ] = useState("");
    const [ acordeonPrin, saveAcordeonPrin ] = useState(null);


    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.maintenance.title+" - "+confPantalla.centrosadj.title;
                    if( confPantalla.centrosadj.panminlvl == null || cookies.get('nivel') <= confPantalla.centrosadj.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            // Seleccionar los parámetros que nos llegan por la url
                            if( props.location.state ){
                                if( "filter" in props.location.state ){
                                    saveFilterTable('"'+props.location.state.filter+'"');
                                }                        
                            }                                                                               
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }             
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          
        
        return () =>{
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){
            case "acordeonPrin":
                if( acordeonPrin == id ){
                    saveAcordeonPrin(null);
                }else{
                    saveAcordeonPrin(id);
                }                
                break;            
        }
    }

    return(
        <SRLWrapper>
            <div className="App wrapper">
                    <Alert fondo={true} />    
                    {
                        confPantalla !== undefined
                        ?
                            <Fragment>
                                <Menusup 
                                    props = { props }
                                />
                                <Sidebar 
                                    props = { props }
                                />
                            </Fragment>
                        :
                        null
                    } 
                <div className="content-wrapper">
                    <div className="content-header"></div>
                    <section className="content">
                    {
                        !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                        <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                <Fragment>
                                    <Section titulo={ confPantalla.centrosadj.sectioninf.titulo } icono={ confPantalla.centrosadj.sectioninf.icono } col={ confPantalla.centrosadj.sectioninf.col } wiki={ confPantalla.centrosadj.sectioninf.wiki } >
                                                <Informes
                                                    select =     { confPantalla.centrosadj.controles.informes.select }
                                                    formulario = { {id:0} }
                                                    loadReg =    { false }
                                                    estadoForm=  { "" } 
                                                    traduction=  { confPantalla.config.trad.components }
                                                    btnfilubi=   { confPantalla.centrosadj.controles.informes.btnfilubi }
                                                />
                                    </Section>
                                    <Section titulo={ confPantalla.centrosadj.sectionadj.titulo } icono={ confPantalla.centrosadj.sectionadj.icono } col={ confPantalla.centrosadj.sectionadj.col } wiki={ confPantalla.centrosadj.sectionadj.wiki } >
                                    <div className='container-fluid'>
                                        {/* <Accordion>
                                            <Card>
                                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonPrin")} className={ acordeonPrin == "0" ? "activeAcordeon" : "" }>
                                                    <i className={ confPantalla.centrosadj.sectionadj.icono } ></i> { confPantalla.centrosadj.sectionadj.titulo}
                                                    {
                                                        acordeonPrin == "0"
                                                        ?
                                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                                        :
                                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                                    <Card.Body> */}
                                                        {/* <UplmInfo
                                                            id =         { confPantalla.centrosadj.controles.uplminfo.id }     
                                                            table    =   { confPantalla.centrosadj.controles.uplminfo }
                                                            traduction=  { confPantalla.config.trad.components.datatable }
                                                            filter=      { filterTable }
                                                        /> */}
                                                        <UplmInfoSearch
                                                            id =         { confPantalla.centrosadj.controles.uplminfosearch.id }     
                                                            uplminfo =   { confPantalla.centrosadj.controles.uplminfosearch }
                                                            traduction=  { confPantalla.config.trad.components }
                                                            filter=      { filterTable }
                                                        />
                                                    {/* </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion> */}
                                    </div>
                                    </Section>   
                                </Fragment>
                                
                                
                                    
                                :
                                    <LoadingPage />
                            }
                        </Fragment>
                    }
                    </section>
                </div>
                <Footer />        
            </div>
        </SRLWrapper>

    );
}

export default CentrosAdj