import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';

import { alertService } from '../../services/alertservice';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, getAhorayyyymmdd, axiosPost } from '../../helpers/helperApp';

import { Fragment } from 'react';

function StockMov(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.stockmov.title;
                    if( confPantalla.stockmov.panminlvl == null || cookies.get('nivel') <= confPantalla.stockmov.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            // Seleccionar los parámetros que nos llegan por la url
                            if( props.location.state ){
                                if( "idubi" in props.location.state || "iduni" in props.location.state ){
                                   // Nos llega la ubicación y la unidad como parámetro
                                   saveFormularioPrin({
                                       ...formularioPrin,
                                       ["idubi"] : props.location.state.idubi,
                                       ["iduni"] : props.location.state.iduni,
                                       ["tipdoc"] : 5
                                   })
                                   const datosLlamada = {
                                        "validacion" : apiKey(),
                                        "tipdoc" : 5,
                                        "idage" : "",
                                        "idcen" : "",
                                        "idequ" : "",
                                        "idapa" : "",
                                        "idubi" : props.location.state.idubi,
                                        "iduni" : props.location.state.iduni,
                                        "ini" : new Date().getFullYear()+"-01-01T00:00",
                                        "fin" : getAhorayyyymmdd()+"T23:59",
                                   };
                                   handleSearchStock(datosLlamada, confPantalla.stockmov.padre.urls, true);

                                }                        
                            }                                                                               
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla, formularioHijo.id])


    const handleSearchStock = async(e, urls, autoCall=false) => {

        let fecIni, fecFin;

        if( !autoCall ){
            e.preventDefault();
            fecIni = e.target[22].value;
            fecFin = e.target[23].value 
        }else{
            fecIni = e["ini"];
            fecFin = e["fin"]; 
        }
        
        if( fecFin < fecIni ){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.config.trad.errors.fec1.title}`, {autoClose: true});
            return;
        }


        saveLoadingTable(true);
        // preparamos los datos del formulario para enviar
        let data;
        if( !autoCall ){
            data = new FormData(e.target);      
            const validacion = apiKey();
            data.append("validacion", validacion);
        }else{
            data = new FormData();
            Object.entries(e).forEach(([key, val]) => {                
                data.append(key,val);
              });  
        }

        // enviamos la petición
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+urls.submit,data);  
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+urls.submit, data, source.token);      
        if( typeof respuesta.data !== "string" ){
            saveRegistrosPrin(respuesta.data);

        }else if( typeof respuesta.data === "string" ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
        } 
        saveLoadingTable(false);
    }

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                titulo={ confPantalla.stockmov.section.titulo } 
                                icono={ confPantalla.stockmov.section.icono } 
                                col={ confPantalla.stockmov.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                        >

                                        <Formulario 
                                                    estadoForm= { confPantalla.stockmov.padre.estadoform }
                                                    loading=    { loadReg ? true : false }
                                                    btnNew={ confPantalla.stockmov.padre.btnform.new }
                                                    btnSave={ confPantalla.stockmov.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.stockmov.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.stockmov.padre.btnform.delete }
                                                    btnClone={ confPantalla.stockmov.padre.btnform.clone }
                                                    btnSearch={ confPantalla.stockmov.padre.btnform.search }
                                                    handleSubmit = { e => handleSearchStock(e, confPantalla.stockmov.padre.urls) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.stockmov.padre.modelform, true, confPantalla.stockmov.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.stockmov.padre.urls, confPantalla.stockmov.padre.modelform, confPantalla.stockmov.padre.tabla, confPantalla.stockmov.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.stockmov.padre.urls, confPantalla.stockmov.hijo.urls, confPantalla.stockmov.padreshijos, confPantalla.stockmov.hijo.modelform, confPantalla.stockmov.padre.tabla, confPantalla.stockmov.title) } 

                                        >
                                  
                                        <FormRow clases="" >
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.tipdoc  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.tipdoc.id }    
                                                name=        { confPantalla.stockmov.padre.controles.tipdoc.name }   
                                                label=       { confPantalla.stockmov.padre.controles.tipdoc.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.tipdoc.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.tipdoc.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.tipdoc.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.tipdoc.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.tipdoc.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.tipdoc.data }
                                                required=    { confPantalla.stockmov.padre.controles.tipdoc.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.tipdoc.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.tipdoc.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.tipdoc.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.tipdoc.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.tipdoc.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.idage  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.idage.id }    
                                                name=        { confPantalla.stockmov.padre.controles.idage.name }   
                                                label=       { confPantalla.stockmov.padre.controles.idage.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.idage.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.idage.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.idage.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.idage.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.idage.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.idage.data }
                                                required=    { confPantalla.stockmov.padre.controles.idage.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.idage.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.idage.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.idage.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.idage.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.idage.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />                                               



                                        </FormRow>
                                        <FormRow>
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.idcen  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.idcen.id }    
                                                name=        { confPantalla.stockmov.padre.controles.idcen.name }   
                                                label=       { confPantalla.stockmov.padre.controles.idcen.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.idcen.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.idcen.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.idcen.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.idcen.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.idcen.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.idcen.data }
                                                required=    { confPantalla.stockmov.padre.controles.idcen.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.idcen.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.idcen.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.idcen.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.idcen.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.idcen.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.idequ  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.idequ.id }    
                                                name=        { confPantalla.stockmov.padre.controles.idequ.name }   
                                                label=       { confPantalla.stockmov.padre.controles.idequ.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.idequ.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.idequ.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.idequ.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.idequ.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.idequ.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.idequ.data }
                                                required=    { confPantalla.stockmov.padre.controles.idequ.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.idequ.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.idequ.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.idequ.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.idequ.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.idequ.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.idapa  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.idapa.id }    
                                                name=        { confPantalla.stockmov.padre.controles.idapa.name }   
                                                label=       { confPantalla.stockmov.padre.controles.idapa.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.idapa.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.idapa.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.idapa.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.idapa.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.idapa.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.idapa.data }
                                                required=    { confPantalla.stockmov.padre.controles.idapa.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.idapa.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.idapa.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.idapa.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.idapa.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.idapa.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.idubi  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.idubi.id }    
                                                name=        { confPantalla.stockmov.padre.controles.idubi.name }   
                                                label=       { confPantalla.stockmov.padre.controles.idubi.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.idubi.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.idubi.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.idubi.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.idubi.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.idubi.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.idubi.data }
                                                extradata=   { confPantalla.stockmov.padre.controles.idubi.extradata }
                                                required=    { confPantalla.stockmov.padre.controles.idubi.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.idubi.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.idubi.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.idubi.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.idubi.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.idubi.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />
                                        </FormRow> 
                                        <FormRow>                                                                                     
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockmov.padre.estadoform }
                                                val=         { formularioPrin.iduni  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.stockmov.padre.controles.iduni.id }    
                                                name=        { confPantalla.stockmov.padre.controles.iduni.name }   
                                                label=       { confPantalla.stockmov.padre.controles.iduni.label }    
                                                placeholder= { confPantalla.stockmov.padre.controles.iduni.placeholder }
                                                col=         { confPantalla.stockmov.padre.controles.iduni.col } 
                                                isClearable= { confPantalla.stockmov.padre.controles.iduni.isclearable }
                                                isSearchable={ confPantalla.stockmov.padre.controles.iduni.issearchable }
                                                dataType=    { confPantalla.stockmov.padre.controles.iduni.datatype }
                                                data=        { confPantalla.stockmov.padre.controles.iduni.data }
                                                required=    { confPantalla.stockmov.padre.controles.iduni.required } 
                                                disabled=  { confPantalla.stockmov.padre.controles.iduni.disabled }
                                                readOnly =   { confPantalla.stockmov.padre.controles.iduni.readonly }
                                                disablethisid={confPantalla.stockmov.padre.controles.iduni.disablethisid}
                                                disableids={confPantalla.stockmov.padre.controles.iduni.disableids}
                                                depends={ confPantalla.stockmov.padre.controles.iduni.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectFormPrin }
                                            />             
                                            <FormInput                                                        
                                                estadoForm= { confPantalla.stockmov.padre.estadoform }
                                                value=      { formularioPrin.ini == null || formularioPrin.ini == "" ? new Date().getFullYear()+"-01-01T00:00" : formularioPrin.ini }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.stockmov.padre.controles.ini.id }   
                                                type=       { confPantalla.stockmov.padre.controles.ini.type }  
                                                name=       { confPantalla.stockmov.padre.controles.ini.name }   
                                                label=      { confPantalla.stockmov.padre.controles.ini.label }                                                       
                                                placeholder={ confPantalla.stockmov.padre.controles.ini.placeholder }    
                                                col=        { confPantalla.stockmov.padre.controles.ini.col } 
                                                required=   { confPantalla.stockmov.padre.controles.ini.required }
                                                readonly=   { confPantalla.stockmov.padre.controles.ini.readonly }                                                
                                                disabled=   { confPantalla.stockmov.padre.controles.ini.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { confPantalla.stockmov.padre.estadoform }
                                                value=      { formularioPrin.fin == null || formularioPrin.fin == "" ? getAhorayyyymmdd()+"T23:59" : formularioPrin.fin }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.stockmov.padre.controles.fin.id }   
                                                type=       { confPantalla.stockmov.padre.controles.fin.type }  
                                                name=       { confPantalla.stockmov.padre.controles.fin.name }   
                                                label=      { confPantalla.stockmov.padre.controles.fin.label }                                                       
                                                placeholder={ confPantalla.stockmov.padre.controles.fin.placeholder }    
                                                col=        { confPantalla.stockmov.padre.controles.fin.col } 
                                                required=   { confPantalla.stockmov.padre.controles.fin.required }
                                                readonly=   { confPantalla.stockmov.padre.controles.fin.readonly }                                                
                                                disabled=   { confPantalla.stockmov.padre.controles.fin.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            /> 
                                        </FormRow>                                              
                                                                                           
                                    </Formulario>
                                    <div style={{height:70, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                        {   loadReg ? 
                                            <Spinner animation="border" />
                                            :
                                            null
                                        }                                
                                        <Alert />
                                    </div>
                                    <MiDataTable 
                                        estadoForm= { confPantalla.stockmov.padre.estadoform }
                                        formulario={ {id:0} }
                                        id={"stockmov"}
                                        columnas={confPantalla.stockmov.padre.datatable.columns}
                                        data={registrosPrin}
                                        buttons={confPantalla.stockmov.padre.datatable.buttons}
                                        ordering={confPantalla.stockmov.padre.datatable.ordering}
                                        order={confPantalla.stockmov.padre.datatable.order}
                                        searching={confPantalla.stockmov.padre.datatable.searching}
                                        paging={confPantalla.stockmov.padre.datatable.paging}
                                        select={confPantalla.stockmov.padre.datatable.select}
                                        pagelength={confPantalla.stockmov.padre.datatable.pagelength}
                                        handleEditReg= { e => handleEditRegPrin(e, confPantalla.stockmov.padre.urls, confPantalla.stockmov.hijo.urls, confPantalla.stockmov.padreshijos, confPantalla.stockmov.hijo.modelform, confPantalla.stockmov.padre.tabla) }
                                        handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.stockmov.padre.urls, confPantalla.stockmov.padre.modelform, confPantalla.stockmov.padre.tabla, confPantalla.stockmov.title) }
                                        saveLoadingTable={ saveLoadingTable }
                                        loadingTable={loadingTable}
                                        columnactions={confPantalla.stockmov.padre.datatable.columnactions.title}
                                        linkColumns={confPantalla.stockmov.padre.datatable.linkColumns}
                                        traduction={confPantalla.config.trad.components.datatable}                                        
                                    />                                             

                                              
                                    
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default StockMov