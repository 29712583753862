import React, { useEffect, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';
import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import { Alert } from '../../componentes/ui/Alert';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Spinner from 'react-bootstrap/Spinner';
import Gant from '../../componentes/ui/Gant';
import Section from '../../componentes/ui/Section';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';

function Historia(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.maintenance.title+" - "+confPantalla.historia.title;
                    if( confPantalla.historia.panminlvl == null || cookies.get('nivel') <= confPantalla.historia.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveEstadoFormPrin(confPantalla.historia.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.historia.hijo.estadoform);                            
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.historia.padre.urls);
                                    saveFormularioPrin(confPantalla.historia.padre.modelform);
                                    saveFormularioHijo(confPantalla.historia.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.historia.padre.urls, confPantalla.historia.hijo.urls, confPantalla.historia.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                                }                        
                            }       
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
            document.querySelector("body").classList.remove("sidebar-open");
            // Token cancelación axios al desmontar componentes
            source.cancel();
            source2.cancel();
        } 

    },[confPantalla])

    return(
        <div className="App wrapper"> 
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
            {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                            // <Section titulo={ confPantalla.historia.section.titulo } icono={ confPantalla.historia.section.icono } col={ confPantalla.historia.section.col } idPrin={ formularioPrin.id } >
                                <Gant 
                                    confPantalla={confPantalla}
                                    idPrin={formularioPrin.id}
                                    idHijo={formularioHijo.id}
                                    formulario={formularioPrin} 
                                />
                            // </Section>
                        :
                        <LoadingPage />
                    }
                    </Fragment>
            }    
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Historia