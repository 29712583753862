import React, { Fragment, useEffect, useState, useContext } from 'react';
import { LoginContext } from '../../../hooks/context/LoginContext';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';
import { HashLink as Link } from 'react-router-hash-link';
import { getApiUrl } from '../../../helpers/helperApp';
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import SpinnerFullScreen from '../../ui/SpinnerFullScreen';



const url = window.location;

function MenuCustom({ miNivel, activeMenuColor, confApp, propiedades }){

    const { apiKey } = useContext(LoginContext);

    const cookies = new Cookies();

    const [ regenerando, saveRegenerando ] = useState(false);
    const [ textoRegenerando, saveTextoRegenerando ] = useState(false);
    const [ estReg, saveEstReg ] = useState("");
    const [ respReg, saveRespReg ] = useState("");
    const [ actual, saveActual ] = useState(0);
    const [ open, saveOpen ] = useState(false); 
    const [ menu, saveMenu ] = useState(null);

    useEffect(() => {
        loadTree();

        readMenu();

    },[])

    const readMenu = async () => {     

        const nivelUsu =  cookies.get('nivel');
        const idiomaUsu =  cookies.get('idioma')

        const res = await axios(url.origin+`/assets/_menu/_${nivelUsu}_${idiomaUsu}.json`);
        saveMenu(res.data.menu);
        
        let indiceActual;

        res.data.menu.map((menuItem, index, menu) => {
            if( menuItem.sub === "" ){
                if(Object.keys(propiedades).length > 0 && propiedades.props.location.state ){
                    if( menuItem.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                        indiceActual = index;
                    }
                }else{
                    if( menuItem.href == url.pathname ){
                        indiceActual = index;
                    }
                }
            }else{
                menuItem.sub.map((menuSubItem, indexSub, menuSub)=>{
                    if(Object.keys(propiedades).length > 0 && propiedades.props.location.state ){
                        if( menuSubItem.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                            indiceActual = index;
                        }
                    }else{
                        if( menuSubItem.href == url.pathname ){
                            indiceActual = index;
                        }                        
                    }
                })
            }
        })

        handleOpen(null, indiceActual);
    }

    const handleOpen = (e, indice) => {
        const menus = document.querySelectorAll(".menuSup");
        for(let i=0; i<menus.length; i++){
            if( menus[i].id == "menuSup"+indice ){
                menus[i].classList.toggle("menu-open");
                if( e == null){                    
                    menus[i].children[0].classList.toggle("active");
                    if( menus[i].children.length > 1 ){
                        if( document.querySelector("#"+menus[i].children["sub"+indice].id) ){
                            const subMenu = document.querySelector("#"+menus[i].children["sub"+indice].id);
                            if( subMenu ){
                                for(let i=0; i<subMenu.children.length; i++){
                                    if(Object.keys(propiedades).length > 0  && propiedades.props.location.state){
                                        if( subMenu.children[i].children[0].dataset.href == url.pathname+"/"+propiedades.props.location.state.filter ){
                                            subMenu.children[i].children[0].classList.add("active");
                                            subMenu.children[i].classList.toggle("active");
                                        }                                    
                                    }
                                }
                            }
                        }
                    }else{
                        menus[i].children[0].classList.add("active");
                    }
                }
            }else{
                if( e == null ){
                    menus[i].children[0].classList.remove("active");
                }
            }
        }
        saveOpen(indice);
    }

    const swalRegenerar = (e) => {
        swal({
            title: confApp.config.trad.components.regstock.titulo.title,
            text: confApp.config.trad.components.regstock.texto.title,
            icon: "warning",
            buttons: {
                cancel : {text: confApp.config.trad.components.regstock.btncancelar.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: confApp.config.trad.components.regstock.btnaceptar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}                
            } 
        }).then(confirmacion => {
            if( confirmacion == true ){
                regenerar();
            }
        })
    }

    const regenerar = async () => {
        saveRegenerando(true);
        saveTextoRegenerando(confApp.config.trad.components.regstock.txtregenerando.title);
        saveEstReg("");

        const data = new FormData();
        data.append("validacion", apiKey());                                   
        const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/regenerarStock', data);
        if( typeof respuesta.data == "string" ){
            // alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: false});
            saveEstReg("error");
            saveTextoRegenerando("");
            saveRespReg(respuesta.data);
        }else if( respuesta.data == true ){
            // alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confApp.config.trad.components.regstock.txtok.title}`, {autoClose: false});
            saveEstReg("success");
            saveTextoRegenerando("");
            saveRespReg(confApp.config.trad.components.regstock.txtok.title);
        }

        // saveRegenerando(false);
    }



    return(

        
            menu != null
            ?
            <Fragment>
                {
                    regenerando
                    ?
                        <SpinnerFullScreen spinner={regenerando} textoSpinner={textoRegenerando} respuesta={respReg} estado={estReg} saveRegenerando={saveRegenerando}  />
                    :
                        null
                }
                  <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" role="menu" data-accordion="true">

                            {
                                menu.map((menuItem, index, menu) => {

                                    let item;

                                    if( menuItem.sub === "" ){
                                        item = 
                                            <li key={index} id={"menuSup"+index} className="menuSup nav-item">
                                                <NavLink data-href={menuItem.href} to={menuItem.href} className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} style={{paddingLeft : "10px"}} >
                                                    <i className={menuItem.icono} />
                                                    <p>{menuItem.title}</p>
                                                </NavLink>
                                            </li>
                                        ;
                                    }else{
                                        item = 
                                            <li key={index} id={"menuSup"+index} className={ open === {index} || actual === {index} ? "menuSup nav-item2 menu-open " : "menuSup nav-item2 "}  onClick={ e => handleOpen(e, index) } >
                                                <a href="#" className={ actual == {index} ? "nav-link2 active" : "nav-link2"}  style={{paddingLeft:10}} >
                                                    <i className={menuItem.icono}></i>
                                                    <p>
                                                        {menuItem.title}
                                                        <i className="fas fa-angle-left right"></i>
                                                    </p>
                                                </a>
                                                <ul id={"sub"+index} className="nav nav-treeview sub">
                                                    {
                                                        menuItem.sub.map((menuSubItem, indexSub, menuSub)=>{
                                                            
                                                            let item;                                                            
                                                            if( menuSubItem.href.toLowerCase().includes("/app/regenerarStock".toLowerCase())  ){                                                               
                                                                item =
                                                                <li key={indexSub} className="nav-item">
                                                                    <a onClick={e=>swalRegenerar(e)} className="nav-link"  style={{cursor:"pointer"}} activeStyle={{ backgroundColor: activeMenuColor }} >
                                                                    <i className={menuSubItem.icono} style={{marginRight:5}} />
                                                                    <p>{menuSubItem.title}</p>
                                                                    </a>
                                                                </li> 
                                                            }else if( menuSubItem.href.includes("http") || menuSubItem.href.includes("/runmac") ){
                                                                item = 
                                                                    <li key={indexSub} className="nav-item">
                                                                        <a data-href={menuSubItem.href} href={menuSubItem.href} target='_blank' className="nav-link"  >
                                                                            <i className={menuSubItem.icono} style={{marginRight:5}} />
                                                                            <p>{menuSubItem.title}</p>
                                                                        </a>
                                                                    </li> 
                                                            }else{
                                                                item = 
                                                                    <li key={indexSub} className="nav-item">
                                                                        <NavLink data-href={menuSubItem.href} to={menuSubItem.href} className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                                                            <i className={menuSubItem.icono} style={{marginRight:5}} />
                                                                            <p>{menuSubItem.title}</p>
                                                                        </NavLink>
                                                                    </li>  
                                                            }

                                                            return(  
                                                                item
                                                            )

                                                        })
                                                    }

                                                </ul>
                                             </li>
                                    }

                                    return(
                                        item
                                    )
                                })
                            }
                        </ul>
                    </nav>
            </Fragment>
            :
                null       
    )
}

export default MenuCustom;