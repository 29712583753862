import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';
import Informes from '../../componentes/formulario/informes/Informes';
import Wizard from '../../componentes/formulario/Wizard/Wizard';
import WizardMateriales from '../../componentes/formulario/WizardMateriales/WizardMateriales';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

import { getApiUrl, axiosPost } from '../../helpers/helperApp';

function Documentostrab(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, saveLoadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo, loadedHijos, saveLoadedHijos } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = confPantalla.config.trad.maintenance.title+" - "+confPantalla.documentostrab.title;
                    if( confPantalla.documentostrab.panminlvl == null || cookies.get('nivel') <= confPantalla.documentostrab.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            saveEstadoFormPrin(confPantalla.documentostrab.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.documentostrab.hijo.estadoform);                            
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.documentostrab.padre.urls);
                                    saveFormularioPrin(confPantalla.documentostrab.padre.modelform);
                                    saveFormularioHijo(confPantalla.documentostrab.hijo.modelform);     
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.hijo.urls, confPantalla.documentostrab.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);     
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                 // Token cancelación axios al desmontar componentes
                source.cancel();
        } 

    },[confPantalla])


    const getEsTraspasoAndHandleSelect = async (selectedItem, nameOfComponent) => {

        // Hacemos una llamada para saber si el tipo de documento es de tipo traspado para mostrar u ocultar la ubicación 2
        if( selectedItem ){
            const data = new FormData();
            data.append("id", selectedItem.value);
            const validacion = apiKey();
            data.append("validacion", validacion);
            const url = `${getApiUrl(window.location.origin)}/documentostrab/getTipoStock`;
            // const res = await axios.post(url, data);
            const res = await axiosPost(url, data, source.token);
            
            if( res.data[0].sto == 4){
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : res.data[0].sto,
                        [nameOfComponent.name]:selectedItem.value                        
                    }
                )
            }else{
                saveFormularioPrin(
                    {
                        ... formularioPrin, 
                        ["tipostock"] : "",
                        [nameOfComponent.name]:selectedItem.value                         
                    }
                )
            }            
           
        }else{
            saveFormularioPrin(
                {
                    ... formularioPrin, 
                    ["tipostock"] : ""                       
                }
            )

        }

    }

    return(
        <div className="App wrapper"> 
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                titulo={ confPantalla.documentostrab.section.titulo } 
                                icono={ confPantalla.documentostrab.section.icono } 
                                col={ confPantalla.documentostrab.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                        >                               
                            <Formulario 
                                        estadoForm= { estadoFormPrin }
                                        loading=    { loadReg ? true : false }
                                        btnNew={ "false" }
                                        btnSave={ "false" }
                                        btnSaveAndNew={ "false" }
                                        btnDelete={ "false" }
                                        btnClone={ "false" }
                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.padre.modelform, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.hijo.modelform, confPantalla.documentostrab.title) } 
                                        formulario = { formularioPrin } 
                                        resetForm = { e => resetFormPrin(confPantalla.documentostrab.padre.modelform, true, confPantalla.documentostrab.padre.tabla) } 
                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.padre.modelform, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.title) } 
                                        handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.hijo.urls, confPantalla.documentostrab.padreshijos, confPantalla.documentostrab.hijo.modelform, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.title) } 

                            >
                                  
                        <div className="noDisplay">                
                            <FormRow clases="" >
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idtip  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idtip.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idtip.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idtip.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idtip.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idtip.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idtip.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idtip.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idtip.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idtip.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idtip.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idtip.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idtip.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idtip.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idtip.disableids}
                                    formulario= { formularioPrin }   
                                    change= { getEsTraspasoAndHandleSelect }
                                />
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idest  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idest.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idest.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idest.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idest.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idest.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idest.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idest.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idest.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idest.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idest.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idest.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idest.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idest.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idest.disableids}
                                    formulario= { formularioPrin }   
                                    change= { handleSelectFormPrin }
                                />  
                            </FormRow>                                       
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idage1  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idage1.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idage1.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idage1.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idage1.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idage1.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idage1.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idage1.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idage1.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idage1.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idage1.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idage1.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idage1.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idage1.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idage1.disableids}
                                    formulario= { formularioPrin }   
                                    change= { handleSelectFormPrin }
                                />
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idage2  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idage2.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idage2.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idage2.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idage2.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idage2.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idage2.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idage2.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idage2.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idage2.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idage2.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idage2.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idage2.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idage2.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idage2.disableids}
                                    formulario= { formularioPrin }   
                                    change= { handleSelectFormPrin }
                                />                                                        
                            </FormRow>
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.cod }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.cod.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.cod.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.cod.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.cod.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.cod.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.cod.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.cod.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.cod.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.cod.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.nom1 }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.nom1.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.nom1.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.nom1.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.nom1.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.nom1.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.nom1.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.nom1.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.nom1.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.nom1.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.nom1_en }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.nom1_en.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.nom1_en.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.nom1_en.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.nom1_en.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.nom1_en.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.nom1_en.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.nom1_en.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.nom1_en.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.nom1_en.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                            </FormRow>
                            <FormRow>
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.fec1 }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.fec1.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.fec1.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.fec1.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.fec1.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.fec1.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.fec1.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.fec1.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.fec1.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.fec1.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.fec2 }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.fec2.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.fec2.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.fec2.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.fec2.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.fec2.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.fec2.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.fec2.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.fec2.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.fec2.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                                <FormInput                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.fec3 }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.fec3.id }   
                                    type=       { confPantalla.documentostrab.padre.controles.fec3.type }  
                                    name=       { confPantalla.documentostrab.padre.controles.fec3.name }   
                                    label=      { confPantalla.documentostrab.padre.controles.fec3.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.fec3.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.fec3.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.fec3.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.fec3.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.fec3.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                            </FormRow>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idcen  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idcen.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idcen.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idcen.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idcen.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idcen.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idcen.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idcen.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idcen.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idcen.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idcen.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idcen.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idcen.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idcen.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idcen.disableids}
                                    formulario= { formularioPrin }   
                                    change= { handleSelectFormPrin }
                                />
                            </FormRow>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idequ  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idequ.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idequ.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idequ.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idequ.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idequ.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idequ.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idequ.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idequ.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idequ.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idequ.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idequ.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idequ.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idequ.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idequ.disableids}
                                    depends={ confPantalla.documentostrab.padre.controles.idequ.depends }
                                    formulario= { formularioPrin }
                                    change= { (selectedItem, nameOfComponent,) => handleSelectFormPrinAndSetValue(selectedItem, nameOfComponent, confPantalla.documentostrab.padre.controles.idequ.setvalue) }
                                />
                            </FormRow>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idapa  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idapa.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idapa.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idapa.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idapa.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idapa.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idapa.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idapa.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idapa.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idapa.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idapa.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idapa.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idapa.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idapa.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idapa.disableids}
                                    formulario= { formularioPrin }
                                    change= { handleSelectFormPrin }
                                />    
                            </FormRow>
                            <FormRow>
                                <MiSelect  
                                    estadoForm=  { estadoFormPrin }
                                    val=         { formularioPrin.idubi  }   
                                    loading=     { loadReg ? true : false }
                                    id=          { confPantalla.documentostrab.padre.controles.idubi.id }    
                                    name=        { confPantalla.documentostrab.padre.controles.idubi.name }   
                                    label=       { confPantalla.documentostrab.padre.controles.idubi.label }    
                                    placeholder= { confPantalla.documentostrab.padre.controles.idubi.placeholder }
                                    col=         { confPantalla.documentostrab.padre.controles.idubi.col } 
                                    isClearable= { confPantalla.documentostrab.padre.controles.idubi.isclearable }
                                    isSearchable={ confPantalla.documentostrab.padre.controles.idubi.issearchable }
                                    dataType=    { confPantalla.documentostrab.padre.controles.idubi.datatype }
                                    data=        { confPantalla.documentostrab.padre.controles.idubi.data }
                                    required=    { confPantalla.documentostrab.padre.controles.idubi.required } 
                                    disabled=  { confPantalla.documentostrab.padre.controles.idubi.disabled }
                                    readOnly =   { confPantalla.documentostrab.padre.controles.idubi.readonly }
                                    disablethisid={confPantalla.documentostrab.padre.controles.idubi.disablethisid}
                                    disableids={confPantalla.documentostrab.padre.controles.idubi.disableids}
                                    depends={ confPantalla.documentostrab.padre.controles.idubi.depends }
                                    formulario= { formularioPrin }   
                                    change= { handleSelectFormPrin }
                                />
                                {
                                    formularioPrin.tipostock == "4"
                                    ?
                                    <MiSelect  
                                        estadoForm=  { estadoFormPrin }
                                        val=         { formularioPrin.idubi2  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.documentostrab.padre.controles.idubi2.id }    
                                        name=        { confPantalla.documentostrab.padre.controles.idubi2.name }   
                                        label=       { confPantalla.documentostrab.padre.controles.idubi2.label }    
                                        placeholder= { confPantalla.documentostrab.padre.controles.idubi2.placeholder }
                                        col=         { confPantalla.documentostrab.padre.controles.idubi2.col } 
                                        isClearable= { confPantalla.documentostrab.padre.controles.idubi2.isclearable }
                                        isSearchable={ confPantalla.documentostrab.padre.controles.idubi2.issearchable }
                                        dataType=    { confPantalla.documentostrab.padre.controles.idubi2.datatype }
                                        data=        { confPantalla.documentostrab.padre.controles.idubi2.data }
                                        required=    { confPantalla.documentostrab.padre.controles.idubi2.required } 
                                        disabled=  { confPantalla.documentostrab.padre.controles.idubi2.disabled }
                                        readOnly =   { confPantalla.documentostrab.padre.controles.idubi2.readonly }
                                        disablethisid={confPantalla.documentostrab.padre.controles.idubi2.disablethisid}
                                        disableids={confPantalla.documentostrab.padre.controles.idubi2.disableids}
                                        depends={ confPantalla.documentostrab.padre.controles.idubi2.depends }
                                        formulario= { formularioPrin }   
                                        change= { handleSelectFormPrin }
                                    />
                                    :
                                    null
                                }
                            </FormRow>                                                  
                                <TextArea                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.notas }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.notas.id }   
                                    name=       { confPantalla.documentostrab.padre.controles.notas.name }
                                    rows=       { confPantalla.documentostrab.padre.controles.notas.rows }      
                                    label=      { confPantalla.documentostrab.padre.controles.notas.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.notas.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.notas.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.notas.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.notas.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.notas.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                                <TextArea                                                        
                                    estadoForm= { estadoFormPrin }
                                    value=      { formularioPrin.notas_en }
                                    loading=    { loadReg ? true : false }
                                    id=         { confPantalla.documentostrab.padre.controles.notas_en.id }   
                                    name=       { confPantalla.documentostrab.padre.controles.notas_en.name }
                                    rows=       { confPantalla.documentostrab.padre.controles.notas_en.rows }      
                                    label=      { confPantalla.documentostrab.padre.controles.notas_en.label }                                                       
                                    placeholder={ confPantalla.documentostrab.padre.controles.notas_en.placeholder }    
                                    col=        { confPantalla.documentostrab.padre.controles.notas_en.col } 
                                    required=   { confPantalla.documentostrab.padre.controles.notas_en.required }
                                    readonly=   { confPantalla.documentostrab.padre.controles.notas_en.readonly }                                                
                                    disabled=   { confPantalla.documentostrab.padre.controles.notas_en.disabled }
                                    change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                    onBlur=     {null} 
                                />
                                                                                         
                            <FormRow clases="" >
                                <Uplm
                                    estadoForm= { estadoFormPrin }
                                    loading=    { loadReg ? true : false }
                                    col={12}
                                    id={ confPantalla.documentostrab.padre.controles.uplm.id }
                                    adjuntos={ adjuntosPrin }
                                    saveAdjuntos= { saveAdjuntosPrin }  
                                    adjuntosExistentes= { adjuntosExistentesPrin } 
                                    saveAdjuntosExistentes= { saveAdjuntosExistentesPrin }
                                    readonly={ confPantalla.documentostrab.padre.controles.uplm.readonly }
                                    traduction={confPantalla.config.trad.components.uplm}
                                />
                            </FormRow>

                                <Informes
                                    select =     { confPantalla.documentostrab.padre.controles.informes.select }
                                    formulario = { formularioPrin }
                                    loadReg =    { loadReg ? true : false }
                                    estadoForm=  { estadoFormPrin } 
                                    traduction=  { confPantalla.config.trad.components }
                                />                                                  
                                                
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iin"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iin.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.iin.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.iin.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iin.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iin.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iin.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iin.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iin.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iin.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iin"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iin.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.iin.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iin.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iin.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iin.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iin.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iin.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iin.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    <FormInput                                                        
                                        estadoForm= { estadoFormPrin }
                                        value=      { formularioPrin["valoracion.cos"] }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.documentostrab.padre.controles.cos.id }   
                                        type=       { confPantalla.documentostrab.padre.controles.cos.type }  
                                        name=       { confPantalla.documentostrab.padre.controles.cos.name }   
                                        label=      { confPantalla.documentostrab.padre.controles.cos.label }                                                       
                                        placeholder={ confPantalla.documentostrab.padre.controles.cos.placeholder }    
                                        col=        { confPantalla.documentostrab.padre.controles.cos.col } 
                                        required=   { confPantalla.documentostrab.padre.controles.cos.required }
                                        readonly=   { confPantalla.documentostrab.padre.controles.cos.readonly }                                                
                                        disabled=   { confPantalla.documentostrab.padre.controles.cos.disabled }
                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                        onBlur=     {null}
                                        alignRight= { true }

                                    />                                           
                                </FormRow>
                                <FormRow clases="text-right">
                                    { 
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.und"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.und.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.und.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.und.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.und.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.und.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.und.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.und.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.und.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.und.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.und"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.und.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.und.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.und.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.und.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.und.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.und.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.und.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.und.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.pre"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.pre.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.pre.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.pre.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.pre.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.pre.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.pre.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.pre.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.pre.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.pre.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null}
                                                alignRight= { true } 
                                            />                                           
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.pre"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.pre.id }   
                                                type=       { "hidden"}  
                                                name=       { confPantalla.documentostrab.padre.controles.pre.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.pre.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.pre.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.pre.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.pre.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.pre.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.pre.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp1"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp1.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.imp1.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp1.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp1.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp1.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp1.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp1.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp1.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp1.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp1"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp1.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp1.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp1.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp1.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp1.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp1.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp1.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp1.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar1"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar1.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.mar1.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar1.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar1.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar1.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar1.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar1.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar1.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar1.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar1"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar1.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar1.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar1.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar1.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar1.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar1.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar1.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar1.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            /> 
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.dto"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.dto.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.dto.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.dto.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.dto.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.dto.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.dto.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.dto.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.dto.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.dto.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.dto"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.dto.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.dto.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.dto.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.dto.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.dto.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.dto.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.dto.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.dto.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.impd"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.impd.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.impd.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.impd.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.impd.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.impd.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.impd.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.impd.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.impd.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.impd.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.impd"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.impd.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.impd.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.impd.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.impd.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.impd.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.impd.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.impd.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.impd.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp2"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp2.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.imp2.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp2.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp2.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp2.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp2.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp2.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp2.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp2.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp2"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp2.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp2.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp2.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp2.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp2.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp2.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp2.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp2.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar2"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar2.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.mar2.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar2.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar2.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar2.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar2.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar2.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar2.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar2.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar2"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar2.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar2.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar2.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar2.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar2.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar2.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar2.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar2.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iva"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iva.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.iva.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.iva.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iva.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iva.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iva.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iva.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iva.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iva.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iva"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iva.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.iva.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iva.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iva.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iva.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iva.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iva.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iva.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iiv"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iiv.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.iiv.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.iiv.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iiv.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iiv.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iiv.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iiv.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iiv.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iiv.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iiv"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iiv.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.iiv.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iiv.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iiv.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iiv.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iiv.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iiv.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iiv.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.rec"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.rec.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.rec.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.rec.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.rec.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.rec.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.rec.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.rec.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.rec.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.rec.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.rec"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.rec.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.rec.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.rec.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.rec.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.rec.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.rec.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.rec.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.rec.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iir"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iir.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.iir.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.iir.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iir.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iir.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iir.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iir.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iir.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iir.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iir"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iir.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.iir.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iir.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iir.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iir.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iir.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iir.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iir.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1" 
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.ret"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.ret.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.ret.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.ret.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.ret.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.ret.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.ret.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.ret.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.ret.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.ret.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.ret"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.ret.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.ret.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.ret.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.ret.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.ret.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.ret.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.ret.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.ret.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                    }
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iit"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iit.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.iit.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.iit.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iit.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iit.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iit.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iit.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iit.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iit.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null}
                                                alignRight= { true } 
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.iit"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.iit.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.iit.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.iit.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.iit.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.iit.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.iit.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.iit.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.iit.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            /> 
                                    }
                                </FormRow>
                                <FormRow clases="text-right">
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp3"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp3.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.imp3.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp3.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp3.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp3.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp3.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp3.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp3.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp3.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            />
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.imp3"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.imp3.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.imp3.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.imp3.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.imp3.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.imp3.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.imp3.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.imp3.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.imp3.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />

                                    }
                                    {
                                        cookies.get('nivel') === "1"
                                        ?
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar3"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar3.id }   
                                                type=       { confPantalla.documentostrab.padre.controles.mar3.type }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar3.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar3.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar3.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar3.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar3.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar3.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar3.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                                alignRight= { true }
                                            /> 
                                        :
                                            <FormInput                                                        
                                                estadoForm= { estadoFormPrin }
                                                value=      { formularioPrin["valoracion.mar3"] }
                                                loading=    { loadReg ? true : false }
                                                id=         { confPantalla.documentostrab.padre.controles.mar3.id }   
                                                type=       { "hidden" }  
                                                name=       { confPantalla.documentostrab.padre.controles.mar3.name }   
                                                label=      { confPantalla.documentostrab.padre.controles.mar3.label }                                                       
                                                placeholder={ confPantalla.documentostrab.padre.controles.mar3.placeholder }    
                                                col=        { confPantalla.documentostrab.padre.controles.mar3.col } 
                                                required=   { confPantalla.documentostrab.padre.controles.mar3.required }
                                                readonly=   { confPantalla.documentostrab.padre.controles.mar3.readonly }                                                
                                                disabled=   { confPantalla.documentostrab.padre.controles.mar3.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            /> 
                                    }
                                </FormRow>                                
                                    <Recurrencia
                                        estadoForm=        { estadoFormPrin }
                                        loading =          { loadReg ? true : false }
                                        formulario =       { formularioPrin }
                                        changeSelect =     { handleSelectFormPrin }
                                        saveFormulario =   { saveFormularioPrin }
                                        handleInput =      { handleInputForm }                                                        
                                        traduction=        { confPantalla.config.trad.components.recurrencia }
                                        noDisplay=         { true }
                                    />   
                            </div>                                              
                            </Formulario>                                     
                                                                            
                    { formularioPrin.id != 0                         
                        ? 
                            <Formulario
                                btnNew={ "false" }
                                btnSave={ "false" }
                                btnSaveAndNew={ "false" }
                                btnDelete={ "false" }
                                btnClone={ "false" } 
                                handleSubmit = { e => handleSubmitHijo(e, confPantalla.documentostrab.hijo.urls, confPantalla.documentostrab.hijo.modelform, confPantalla.documentostrab.hijo.tabla, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.title2) } 
                                formulario = { formularioHijo } 
                                resetForm = { e => resetFormHijo(confPantalla.documentostrab.hijo.modelform) } 
                                handleDeleteItem = { e => handleDeleteItemHijo(undefined, confPantalla.documentostrab.hijo.urls, confPantalla.documentostrab.hijo.modelform, confPantalla.documentostrab.hijo.tabla, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.title2) }
                                handleCloneItem = { e => handleCloneHijo(formularioHijo.id, confPantalla.documentostrab.hijo.urls, confPantalla.documentostrab.hijo.tabla, confPantalla.documentostrab.padre.urls, confPantalla.documentostrab.padre.tabla, confPantalla.documentostrab.title2) }
                                esHijo= { true }
                                formularioPadre= { formularioPrin }  
                            >
                                <input type="hidden" name="iddoc" id="lineas.iddoc" value={ formularioPrin.id } />

                            {/* {
                                formularioPrin.idtip != 18
                                ?
                                    <Wizard 
                                        titulo={"Titulo del wizard"}
                                        cabecera={ formularioPrin }
                                        registrosHijo={ registrosHijo }
                                        controles={ confPantalla.documentostrab.hijo.controles }
                                        estadoForm={ estadoFormHijo }
                                        adjuntos={ adjuntosHijo }
                                        adjuntosExistentes= { adjuntosExistentesHijo } 
                                        confPantalla={ confPantalla }
                                        saveLoadReg={ saveLoadReg }
                                        propiedades={ props }
                                        adjuntosCabecera={ adjuntosExistentesPrin }
                                        informeSelect = { confPantalla.documentostrab.padre.controles.informes.select }
                                        informeLoadReg = { loadReg }
                                        informeEstadoForm = { estadoFormPrin }
                                        informeTraduction = { confPantalla.config.trad.components }
                                        loadedHijos={ loadedHijos }
                                    />                            
                                : */}
                                    <WizardMateriales 
                                        titulo={"Titulo del wizard"}
                                        cabecera={ formularioPrin }
                                        registrosHijo={ registrosHijo }
                                        controles={ confPantalla.documentostrab.hijo.controles }
                                        estadoForm={ estadoFormHijo }
                                        adjuntos={ adjuntosHijo }
                                        adjuntosExistentes= { adjuntosExistentesHijo } 
                                        confPantalla={ confPantalla }
                                        saveLoadReg={ saveLoadReg }
                                        propiedades={ props }
                                        adjuntosCabecera={ adjuntosExistentesPrin }
                                        informeSelect = { confPantalla.documentostrab.padre.controles.informes.select }
                                        informeLoadReg = { loadReg }
                                        informeEstadoForm = { estadoFormPrin }
                                        informeTraduction = { confPantalla.config.trad.components }
                                        loadedHijos={ loadedHijos }
                                    />                                                                
                            {/* } */}
                            <div className="noDisplay">               
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioHijo.idage1  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idage1.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idage1.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idage1.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idage1.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idage1.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idage1.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idage1.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idage1.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idage1.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idage1.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idage1.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idage1.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idage1.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idage1.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        />
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioHijo.idage2  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idage2.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idage2.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idage2.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idage2.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idage2.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idage2.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idage2.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idage2.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idage2.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idage2.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idage2.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idage2.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idage2.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idage2.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        />                                                        
                                    </FormRow>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioHijo.idest == "0" || formularioHijo.idest == "" ? formularioPrin.idest : formularioHijo.idest  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idest.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idest.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idest.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idest.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idest.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idest.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idest.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idest.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idest.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idest.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idest.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idest.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idest.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idest.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        />  
                                    </FormRow>
                                    <FormRow>
                                        <FormInput                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.fec1 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.fec1.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.fec1.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.fec1.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.fec1.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.fec1.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.fec1.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.fec1.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.fec1.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.padre.controles.fec1.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.fec2 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.fec2.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.fec2.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.fec2.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.fec2.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.fec2.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.fec2.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.fec2.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.fec2.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.fec2.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.fec3 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.fec3.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.fec3.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.fec3.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.fec3.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.fec3.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.fec3.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.fec3.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.fec3.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.fec3.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.txt1 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.txt1.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.txt1.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.txt1.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.txt1.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.txt1.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.txt1.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.txt1.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.txt1.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.txt1.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.txt2 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.txt2.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.txt2.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.txt2.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.txt2.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.txt2.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.txt2.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.txt2.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.txt2.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.txt2.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.txt3 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.txt3.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.txt3.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.txt3.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.txt3.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.txt3.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.txt3.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.txt3.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.txt3.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.txt3.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.num1 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.num1.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.num1.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.num1.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.num1.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.num1.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.num1.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.num1.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.num1.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.num1.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.num2 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.num2.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.num2.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.num2.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.num2.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.num2.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.num2.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.num2.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.num2.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.num2.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioHijo.iduni  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.iduni.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.iduni.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.iduni.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.iduni.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.iduni.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.iduni.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.iduni.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.iduni.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.iduni.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.iduni.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.iduni.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.iduni.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.iduni.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.iduni.disableids}
                                            formulario= { formularioHijo }   
                                            change= { (selectedItem, nameOfComponent) => handleSelectFormHijoAndSetValue(selectedItem, nameOfComponent, confPantalla.documentostrab.hijo.controles.iduni.setvalue) }
                                        />
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.num3 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.num3.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.num3.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.num3.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.num3.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.num3.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.num3.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.num3.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.num3.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.num3.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />                                                           
                                    </FormRow>
                                    <FormRow>
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.nom1 }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.nom1.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.nom1.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.nom1.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.nom1.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.nom1.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.nom1.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.nom1.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.nom1.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.nom1.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            
                                            onBlur=     {null} 
                                        />
                                        <FormInput 
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.nom1_en }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.nom1_en.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.nom1_en.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.nom1_en.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.nom1_en.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.nom1_en.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.nom1_en.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.nom1_en.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.nom1_en.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.nom1_en.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            
                                            onBlur=     {null} 
                                        />
                                    </FormRow>
                                    <FormRow> 
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioPrin.idequ  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idequ.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idequ.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idequ.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idequ.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idequ.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idequ.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idequ.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idequ.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idequ.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idequ.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idequ.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idequ.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idequ.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idequ.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        /> 
                                    </FormRow>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioPrin.idapa  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idapa.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idapa.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idapa.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idapa.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idapa.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idapa.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idapa.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idapa.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idapa.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idapa.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idapa.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idapa.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idapa.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idapa.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <MiSelect  
                                            estadoForm=  { estadoFormHijo }
                                            val=         { formularioPrin.idubi  }   
                                            loading=     { loadReg ? true : false }
                                            id=          { confPantalla.documentostrab.hijo.controles.idubi.id }    
                                            name=        { confPantalla.documentostrab.hijo.controles.idubi.name }   
                                            label=       { confPantalla.documentostrab.hijo.controles.idubi.label }    
                                            placeholder= { confPantalla.documentostrab.hijo.controles.idubi.placeholder }
                                            col=         { confPantalla.documentostrab.hijo.controles.idubi.col } 
                                            isClearable= { confPantalla.documentostrab.hijo.controles.idubi.isclearable }
                                            isSearchable={ confPantalla.documentostrab.hijo.controles.idubi.issearchable }
                                            dataType=    { confPantalla.documentostrab.hijo.controles.idubi.datatype }
                                            data=        { confPantalla.documentostrab.hijo.controles.idubi.data }
                                            required=    { confPantalla.documentostrab.hijo.controles.idubi.required } 
                                            disabled=  { confPantalla.documentostrab.hijo.controles.idubi.disabled }
                                            readOnly =   { confPantalla.documentostrab.hijo.controles.idubi.readonly }
                                            disablethisid={confPantalla.documentostrab.hijo.controles.idubi.disablethisid}
                                            disableids={confPantalla.documentostrab.hijo.controles.idubi.disableids}
                                            formulario= { formularioHijo }   
                                            change= { handleSelectFormHijo }
                                        />
                                        {
                                            formularioPrin.tipostock == "4"
                                            ?
                                            <MiSelect  
                                                estadoForm=  { estadoFormHijo }
                                                val=         { formularioPrin.idubi2  }   
                                                loading=     { loadReg ? true : false }
                                                id=          { confPantalla.documentostrab.hijo.controles.idubi2.id }    
                                                name=        { confPantalla.documentostrab.hijo.controles.idubi2.name }   
                                                label=       { confPantalla.documentostrab.hijo.controles.idubi2.label }    
                                                placeholder= { confPantalla.documentostrab.hijo.controles.idubi2.placeholder }
                                                col=         { confPantalla.documentostrab.hijo.controles.idubi2.col } 
                                                isClearable= { confPantalla.documentostrab.hijo.controles.idubi2.isclearable }
                                                isSearchable={ confPantalla.documentostrab.hijo.controles.idubi2.issearchable }
                                                dataType=    { confPantalla.documentostrab.hijo.controles.idubi2.datatype }
                                                data=        { confPantalla.documentostrab.hijo.controles.idubi2.data }
                                                required=    { confPantalla.documentostrab.hijo.controles.idubi2.required } 
                                                disabled=  { confPantalla.documentostrab.hijo.controles.idubi2.disabled }
                                                readOnly =   { confPantalla.documentostrab.hijo.controles.idubi2.readonly }
                                                disablethisid={confPantalla.documentostrab.hijo.controles.idubi2.disablethisid}
                                                disableids={confPantalla.documentostrab.hijo.controles.idubi2.disableids}
                                                formulario= { formularioHijo }   
                                                change= { handleSelectFormHijo }
                                            />
                                            :
                                            null
                                        }                                                                
                                    </FormRow>                                                            
                                                        
                                        <TextArea                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.notas }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.notas.id }   
                                            name=       { confPantalla.documentostrab.hijo.controles.notas.name }
                                            rows=       { confPantalla.documentostrab.hijo.controles.notas.rows }      
                                            label=      { confPantalla.documentostrab.hijo.controles.notas.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.notas.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.notas.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.notas.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.notas.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.notas.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />
                                        <TextArea                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo.notas_en }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.notas_en.id }   
                                            name=       { confPantalla.documentostrab.hijo.controles.notas_en.name }
                                            rows=       { confPantalla.documentostrab.hijo.controles.notas_en.rows }      
                                            label=      { confPantalla.documentostrab.hijo.controles.notas_en.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.notas_en.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.notas_en.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.notas_en.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.notas_en.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.notas_en.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                        />                                                        
                                    <FormRow clases="" >
                                        <Uplm
                                            estadoForm= { estadoFormHijo }
                                            loading=    { loadReg ? true : false }
                                            col={12}
                                            id={confPantalla.documentostrab.hijo.controles.uplm.id}
                                            adjuntos={ adjuntosHijo }
                                            saveAdjuntos= { saveAdjuntosHijo }  
                                            adjuntosExistentes= { adjuntosExistentesHijo } 
                                            saveAdjuntosExistentes= { saveAdjuntosExistentesHijo }          
                                            readonly={ confPantalla.documentostrab.hijo.controles.uplm.readonly }
                                            traduction={confPantalla.config.trad.components.uplm}
                                        />
                                    </FormRow>
                                                        
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iin"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iin.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.iin.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iin.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iin.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iin.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iin.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iin.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iin.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iin.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iin"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iin.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iin.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iin.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iin.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iin.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iin.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iin.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iin.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />

                                        }
                                        <FormInput                                                        
                                            estadoForm= { estadoFormHijo }
                                            value=      { formularioHijo["valoracion.cos"] }
                                            loading=    { loadReg ? true : false }
                                            id=         { confPantalla.documentostrab.hijo.controles.cos.id }   
                                            type=       { confPantalla.documentostrab.hijo.controles.cos.type }  
                                            name=       { confPantalla.documentostrab.hijo.controles.cos.name }   
                                            label=      { confPantalla.documentostrab.hijo.controles.cos.label }                                                       
                                            placeholder={ confPantalla.documentostrab.hijo.controles.cos.placeholder }    
                                            col=        { confPantalla.documentostrab.hijo.controles.cos.col } 
                                            required=   { confPantalla.documentostrab.hijo.controles.cos.required }
                                            readonly=   { confPantalla.documentostrab.hijo.controles.cos.readonly }                                                
                                            disabled=   { confPantalla.documentostrab.hijo.controles.cos.disabled }
                                            change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                            onBlur=     {null} 
                                            alignRight= { true }
                                        />                                           
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.und"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.und.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.und.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.und.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.und.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.und.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.und.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.und.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.und.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.und.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.und"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.und.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.und.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.und.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.und.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.und.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.und.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.und.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.und.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.pre"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.pre.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.pre.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.pre.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.pre.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.pre.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.pre.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.pre.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.pre.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.pre.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />                                           
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.pre"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.pre.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.pre.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.pre.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.pre.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.pre.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.pre.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.pre.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.pre.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp1"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp1.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.imp1.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp1.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp1.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp1.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp1.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp1.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp1.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp1.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp1"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp1.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp1.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp1.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp1.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp1.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp1.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp1.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp1.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar1"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar1.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.mar1.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar1.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar1.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar1.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar1.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar1.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar1.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar1.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar1"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar1.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar1.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar1.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar1.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar1.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar1.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar1.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar1.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.dto"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.dto.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.dto.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.dto.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.dto.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.dto.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.dto.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.dto.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.dto.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.dto.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.dto"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.dto.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.dto.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.dto.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.dto.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.dto.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.dto.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.dto.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.dto.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.impd"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.impd.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.impd.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.impd.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.impd.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.impd.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.impd.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.impd.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.impd.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.impd.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.impd"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.impd.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.impd.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.impd.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.impd.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.impd.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.impd.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.impd.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.impd.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp2"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp2.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.imp2.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp2.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp2.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp2.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp2.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp2.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp2.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp2.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp2"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp2.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp2.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp2.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp2.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp2.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp2.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp2.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp2.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar2"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar2.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.mar2.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar2.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar2.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar2.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar2.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar2.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar2.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar2.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar2"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar2.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar2.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar2.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar2.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar2.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar2.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar2.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar2.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iva"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iva.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.iva.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iva.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iva.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iva.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iva.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iva.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iva.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iva.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iva"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iva.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iva.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iva.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iva.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iva.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iva.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iva.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iva.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iiv"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iiv.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.iiv.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iiv.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iiv.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iiv.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iiv.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iiv.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iiv.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iiv.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iiv"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iiv.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iiv.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iiv.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iiv.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iiv.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iiv.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iiv.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iiv.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.rec"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.rec.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.rec.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.rec.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.rec.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.rec.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.rec.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.rec.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.rec.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.rec.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null}
                                                    alignRight= { true } 
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.rec"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.rec.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.rec.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.rec.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.rec.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.rec.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.rec.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.rec.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.rec.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iir"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iir.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.iir.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iir.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iir.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iir.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iir.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iir.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iir.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iir.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iir"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iir.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iir.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iir.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iir.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iir.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iir.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iir.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iir.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.ret"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.ret.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.ret.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.ret.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.ret.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.ret.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.ret.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.ret.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.ret.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.ret.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.ret"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.ret.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.ret.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.ret.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.ret.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.ret.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.ret.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.ret.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.ret.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iit"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iit.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.iit.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iit.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iit.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iit.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iit.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iit.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iit.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iit.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.iit"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.iit.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.iit.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.iit.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.iit.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.iit.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.iit.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.iit.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.iit.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>
                                    <FormRow clases="text-right">
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp3"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp3.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.imp3.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp3.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp3.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp3.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp3.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp3.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp3.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp3.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                />
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.imp3"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.imp3.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.imp3.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.imp3.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.imp3.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.imp3.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.imp3.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.imp3.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.imp3.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                        {
                                            cookies.get('nivel') === "1"
                                            ?
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar3"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar3.id }   
                                                    type=       { confPantalla.documentostrab.hijo.controles.mar3.type }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar3.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar3.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar3.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar3.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar3.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar3.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar3.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                    alignRight= { true }
                                                /> 
                                            :
                                                <FormInput                                                        
                                                    estadoForm= { estadoFormHijo }
                                                    value=      { formularioHijo["valoracion.mar3"] }
                                                    loading=    { loadReg ? true : false }
                                                    id=         { confPantalla.documentostrab.hijo.controles.mar3.id }   
                                                    type=       { "hidden" }  
                                                    name=       { confPantalla.documentostrab.hijo.controles.mar3.name }   
                                                    label=      { confPantalla.documentostrab.hijo.controles.mar3.label }                                                       
                                                    placeholder={ confPantalla.documentostrab.hijo.controles.mar3.placeholder }    
                                                    col=        { confPantalla.documentostrab.hijo.controles.mar3.col } 
                                                    required=   { confPantalla.documentostrab.hijo.controles.mar3.required }
                                                    readonly=   { confPantalla.documentostrab.hijo.controles.mar3.readonly }                                                
                                                    disabled=   { confPantalla.documentostrab.hijo.controles.mar3.disabled }
                                                    change=     { e => handleInputForm(e, formularioHijo, saveFormularioHijo) }
                                                    onBlur=     {null} 
                                                />
                                        }
                                    </FormRow>                                                        
                                        <Recurrencia
                                            estadoForm=        { estadoFormHijo }
                                            loading =          { loadReg ? true : false }
                                            formulario =       { formularioHijo }
                                            changeSelect =     { handleSelectFormHijo }
                                            saveFormulario =   { saveFormularioHijo }
                                            handleInput =      { handleInputForm }                                                        
                                            traduction=        { confPantalla.config.trad.components.recurrencia }
                                            noDisplay=         { true }
                                        />
                                </div>  
                                </Formulario>                                                          
                            :
                                null
                    }
                                    
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default Documentostrab