import React, { useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { loadTree } from '../../../helpers/MenuTreeHelper';

const url = window.location;

function MenuTrab({ miNivel, activeMenuColor, confApp }){

    const [ actual, saveActual ] = useState(0);
    const [ open1, saveOpen1 ] = useState(false); 


    useEffect(() => {
        loadTree();

        if( url.pathname.includes("/edirap") ){
            saveActual(1);
        }

    },[])

    const handleOpen = (e, open, saveOpen, indice) => {

        if( open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('openMenu');  
                subMenu.classList.add('closeMenu');             
            }
        }else if( !open && indice ){
            let subMenu = document.querySelector(`#sub${indice}`);
            if( subMenu ){
                subMenu.classList.remove('closeMenu');  
                subMenu.classList.add('openMenu');               
            }
        }
        saveOpen(!open);
    }

    return(
              
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column  nav-child-indent" data-widget="treeview" data-api="tree" data-animation-speed="600" role="menu" data-accordion="true">
                {
                    miNivel <= confApp.documentostrab.panminlvl
                    ?
                    <li className="nav-item">
                        <NavLink to="/inicio" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.documentostrab.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.documentostrab.sidebarmenu.titulo}</p>
                        </NavLink>
                    </li>  
                    :
                    null
                }
                <li className="nav-item">
                    <NavLink to="/checklisttrab" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                        <i className={confApp.checklist.sidebarmenu.icono} style={{marginRight:5}} />
                        <p>{confApp.checklist.sidebarmenu.titulo}</p>
                    </NavLink>
                </li>             
            {
                miNivel <= confApp.edirap.panminlvl
                ?
                <li className={ open1 === true || actual === 1  ? " nav-item2 menu-open " : " nav-item2 "}  onClick={ e => handleOpen(e, open1, saveOpen1, 1) } >
                    <a href="#" className={ actual == 1  ? "nav-link2 active" : "nav-link2"}  >
                        <i className={confApp.menutrab.nivelessup.stock.icono}></i>
                        <p>
                            {confApp.menutrab.nivelessup.stock.titulo}
                            <i className="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul id="sub1" className="nav nav-treeview sub">
                        <li className="nav-item">
                            <NavLink to="/runmac/bd0789da-d7ea-11ed-ab36-005056011ce4" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.menutrab.stock.icono} style={{marginRight:5}} />
                                <p  style={{paddingLeft:5}}>{confApp.menutrab.stock.titulo}</p>
                            </NavLink>
                        </li>  
                        {
                            miNivel <= confApp.edirap.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/edirapinv/14" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.edirap.sidebarmenu.icono_inv} style={{marginRight:5}} />
                                <p>{confApp.edirap.sidebarmenu.titulo_inv}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }
                        {
                            miNivel <= confApp.edirap.panminlvl
                            ?
                            <li className="nav-item">
                            <NavLink to="/edirapcomp/11" className="nav-link" activeStyle={{ backgroundColor: activeMenuColor }} >
                                <i className={confApp.edirap.sidebarmenu.icono_comp} style={{marginRight:5}} />
                                <p>{confApp.edirap.sidebarmenu.titulo_comp}</p>
                            </NavLink>
                            </li>  
                            :
                            null
                        }                                                             
                    </ul>
                </li>
                :
                null
            }            
            </ul>
        </nav>                          

       

    )
}

export default MenuTrab;