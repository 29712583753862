import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect from '../../componentes/formulario/MiSelect';
import TextArea from '../../componentes/formulario/TextArea';
import Uplm from '../../componentes/formulario/Uplm/Uplm';
import Upls from '../../componentes/formulario/Upls/Upls';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Recurrencia from '../../componentes/formulario/forms/Recurrencia';

import { alertService } from '../../services/alertservice';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, getAhorayyyymmdd, axiosPost } from '../../helpers/helperApp';

import { Fragment } from 'react';

function StockArt(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ loadingArticulo, saveLoadingArticulo ] = useState(false);
    const [ datosTableStockArt, saveDatosTableStockArt ] = useState([]);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.stockart.title;
                    if( confPantalla.stockart.panminlvl == null || cookies.get('nivel') <= confPantalla.stockart.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);
                            // Seleccionar los parámetros que nos llegan por la url
                            if( props.location.state ){
                                if( "iduni" in props.location.state ){
                                   // Nos llega la unidad como parámetro
                                   saveFormularioPrin({
                                       ...formularioPrin,                                       
                                       ["iduni"] : props.location.state.iduni
                                   })
                                   // Cargamos el artículo recibido por parámetro
                                   getArticulo(props.location.state.iduni);                                
                                }                        
                            }                                                                               
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
        } 

    },[confPantalla, formularioHijo.id])


    const handleSelectUni = (selectedItem, nameOfComponent) => {

        if( selectedItem ){
            getArticulo(selectedItem.value)  
        }

        handleSelectFormPrin(selectedItem, nameOfComponent)
    }

    const getArticulo = async (idUni) => {
        
        saveLoadingArticulo(true);

        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("id", idUni);
        // enviamos la petición
        // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.stockart.padre.urls.get,data);        
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.stockart.padre.urls.get, data, source.token);
        if( typeof respuesta.data !== "string" ){
            // saveRegistrosPrin(respuesta.data);
            saveFormularioPrin(respuesta.data[0]);
            handleSearchStock(idUni);

        }else if( typeof respuesta.data === "string" ){            
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
        } 

        saveLoadingArticulo(false);

    }

    const handleSearchStock = async(id) => {

        saveLoadingTable(true);
        // preparamos los datos para enviar
        if( id != "" ){
            const data = new FormData();      
            data.append("validacion", apiKey());
            data.append("id", id);

            // enviamos la petición
            // const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.stockart.padre.controles.datatable.getdata,data);  
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.stockart.padre.controles.datatable.getdata, data, source2.token);      
            if( typeof respuesta.data !== "string" ){
                saveDatosTableStockArt(respuesta.data);
    
            }else if( typeof respuesta.data === "string" ){            
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true});            
            } 
        }

        saveLoadingTable(false);
    }

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <Section 
                                titulo={ confPantalla.stockart.section.titulo } 
                                icono={ confPantalla.stockart.section.icono } 
                                col={ confPantalla.stockart.section.col } 
                                idPrin={ formularioPrin.id }
                                idHijo={ formularioHijo.id }
                        >

                                        <Formulario 
                                                    estadoForm= { confPantalla.stockart.padre.estadoform }
                                                    loading=    { loadingArticulo ? true : false }
                                                    btnNew={ confPantalla.stockart.padre.btnform.new }
                                                    btnSave={ confPantalla.stockart.padre.btnform.save }
                                                    btnSaveAndNew={ confPantalla.stockart.padre.btnform.saveandnew }
                                                    btnDelete={ confPantalla.stockart.padre.btnform.delete }
                                                    btnClone={ confPantalla.stockart.padre.btnform.clone }
                                                    btnSearch={ confPantalla.stockart.padre.btnform.search }
                                                    handleSubmit = { e => handleSubmitPrin(e, confPantalla.stockart.padre.urls, confPantalla.stockart.padre.modelform, confPantalla.stockart.padre.tabla, confPantalla.stockart.hijo.modelform, confPantalla.stockart.title) } 
                                                    formulario = { formularioPrin } 
                                                    resetForm = { e => resetFormPrin(confPantalla.stockart.padre.modelform, true, confPantalla.stockart.padre.tabla) } 
                                                    handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.stockart.padre.urls, confPantalla.stockart.padre.modelform, confPantalla.stockart.padre.tabla, confPantalla.stockart.title) } 
                                                    handleCloneItem = { e => handleClonePrin(formularioPrin.id, confPantalla.stockart.padre.urls, confPantalla.stockart.hijo.urls, confPantalla.stockart.padreshijos, confPantalla.stockart.hijo.modelform, confPantalla.stockart.padre.tabla, confPantalla.stockart.title) } 

                                        >                                  
                                        <FormRow clases="" >
                                            <MiSelect  
                                                estadoForm=  { confPantalla.stockart.padre.estadoform }
                                                val=         { formularioPrin.id != null ? formularioPrin.id : 0 }   
                                                loading=     { loadingArticulo ? true : false }
                                                id=          { confPantalla.stockart.padre.controles.iduni.id }    
                                                name=        { confPantalla.stockart.padre.controles.iduni.name }   
                                                label=       { confPantalla.stockart.padre.controles.iduni.label }    
                                                placeholder= { confPantalla.stockart.padre.controles.iduni.placeholder }
                                                col=         { confPantalla.stockart.padre.controles.iduni.col } 
                                                isClearable= { confPantalla.stockart.padre.controles.iduni.isclearable }
                                                isSearchable={ confPantalla.stockart.padre.controles.iduni.issearchable }
                                                dataType=    { confPantalla.stockart.padre.controles.iduni.datatype }
                                                data=        { confPantalla.stockart.padre.controles.iduni.data }
                                                required=    { confPantalla.stockart.padre.controles.iduni.required } 
                                                disabled=  { confPantalla.stockart.padre.controles.iduni.disabled }
                                                readOnly =   { confPantalla.stockart.padre.controles.iduni.readonly }
                                                disablethisid={confPantalla.stockart.padre.controles.iduni.disablethisid}
                                                disableids={confPantalla.stockart.padre.controles.iduni.disableids}
                                                depends={ confPantalla.stockart.padre.controles.iduni.depends }
                                                formulario= { formularioPrin }   
                                                change= { handleSelectUni }
                                            />
                                        </FormRow>                                        
                                        <FormRow>
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.cod1 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.refprivada.id }   
                                                type=       { confPantalla.stockart.padre.controles.refprivada.type }  
                                                name=       { confPantalla.stockart.padre.controles.refprivada.name }   
                                                label=      { confPantalla.stockart.padre.controles.refprivada.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.refprivada.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.refprivada.col } 
                                                required=   { confPantalla.stockart.padre.controles.refprivada.required }
                                                readonly=   { confPantalla.stockart.padre.controles.refprivada.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.refprivada.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />                                            
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.cod2 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.refpublica.id }   
                                                type=       { confPantalla.stockart.padre.controles.refpublica.type }  
                                                name=       { confPantalla.stockart.padre.controles.refpublica.name }   
                                                label=      { confPantalla.stockart.padre.controles.refpublica.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.refpublica.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.refpublica.col } 
                                                required=   { confPantalla.stockart.padre.controles.refpublica.required }
                                                readonly=   { confPantalla.stockart.padre.controles.refpublica.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.refpublica.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.num3 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.stomin.id }   
                                                type=       { confPantalla.stockart.padre.controles.stomin.type }  
                                                name=       { confPantalla.stockart.padre.controles.stomin.name }   
                                                label=      { confPantalla.stockart.padre.controles.stomin.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.stomin.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.stomin.col } 
                                                required=   { confPantalla.stockart.padre.controles.stomin.required }
                                                readonly=   { confPantalla.stockart.padre.controles.stomin.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.stomin.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.num1 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.stoact.id }   
                                                type=       { confPantalla.stockart.padre.controles.stoact.type }  
                                                name=       { confPantalla.stockart.padre.controles.stoact.name }   
                                                label=      { confPantalla.stockart.padre.controles.stoact.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.stoact.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.stoact.col } 
                                                required=   { confPantalla.stockart.padre.controles.stoact.required }
                                                readonly=   { confPantalla.stockart.padre.controles.stoact.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.stoact.disabled }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                        </FormRow> 
                                        <FormRow>
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.nom1 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.descripcion1.id }   
                                                type=       { confPantalla.stockart.padre.controles.descripcion1.type }  
                                                name=       { confPantalla.stockart.padre.controles.descripcion1.name }   
                                                label=      { confPantalla.stockart.padre.controles.descripcion1.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.descripcion1.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.descripcion1.col } 
                                                required=   { confPantalla.stockart.padre.controles.descripcion1.required }
                                                readonly=   { confPantalla.stockart.padre.controles.descripcion1.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.descripcion1.disabled }
                                                autoComplete={confPantalla.stockart.padre.controles.descripcion1.autocomplete }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />
                                            <FormInput                                                        
                                                estadoForm= { "" }
                                                value=      { formularioPrin.nom2 }
                                                loading=    { loadingArticulo ? true : false }
                                                id=         { confPantalla.stockart.padre.controles.descripcion2.id }   
                                                type=       { confPantalla.stockart.padre.controles.descripcion2.type }  
                                                name=       { confPantalla.stockart.padre.controles.descripcion2.name }   
                                                label=      { confPantalla.stockart.padre.controles.descripcion2.label }                                                       
                                                placeholder={ confPantalla.stockart.padre.controles.descripcion2.placeholder }    
                                                col=        { confPantalla.stockart.padre.controles.descripcion2.col } 
                                                required=   { confPantalla.stockart.padre.controles.descripcion2.required }
                                                readonly=   { confPantalla.stockart.padre.controles.descripcion2.readonly }                                                
                                                disabled=   { confPantalla.stockart.padre.controles.descripcion2.disabled }
                                                autoComplete={confPantalla.stockart.padre.controles.descripcion2.autocomplete }
                                                change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                onBlur=     {null} 
                                            />                                                                                               
                                        </FormRow>                                           
                                    </Formulario>
                                    <div style={{height:70, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                        {   loadReg ? 
                                            <Spinner animation="border" />
                                            :
                                            null
                                        }                                
                                        <Alert />
                                    </div>
                                    <MiDataTable 
                                        estadoForm= { "" }
                                        formulario={ {id:0} }
                                        id={"stockart"}
                                        columnas={confPantalla.stockart.padre.controles.datatable.columns}
                                        data={datosTableStockArt}
                                        linkColumns={confPantalla.stockart.padre.controles.datatable.linkColumns}
                                        buttons={confPantalla.stockart.padre.controles.datatable.buttons}
                                        ordering={confPantalla.stockart.padre.controles.datatable.ordering}
                                        order={confPantalla.stockart.padre.controles.datatable.order}
                                        searching={confPantalla.stockart.padre.controles.datatable.searching}
                                        paging={confPantalla.stockart.padre.controles.datatable.paging}
                                        select={confPantalla.stockart.padre.controles.datatable.select}
                                        pagelength={confPantalla.stockart.padre.controles.datatable.pagelength}
                                        saveLoadingTable={ saveLoadingTable }
                                        loadingTable={loadingTable}
                                        columnactions={confPantalla.stockart.padre.controles.datatable.title}
                                        traduction={confPantalla.config.trad.components.datatable}
                                    />       
                                              
                                    
                                                                
                            </Section>
                        :
                            <LoadingPage />
                        }
                    </Fragment>
                }
                
            </section>
        </div>
        <Footer />        
        </div>

    );

}
export default StockArt