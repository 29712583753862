import React, { useEffect, useContext, useState } from 'react';

import { LoginContext } from '../../hooks/context/LoginContext';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Formulario from '../../componentes/formulario/Formulario';
import FormRow from '../../componentes/formulario/FormRow';
import FormInput from '../../componentes/formulario/FormInput';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import { Alert } from '../../componentes/ui/Alert';
import MiSelect  from '../../componentes/formulario/MiSelect';

import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';

import Spinner from 'react-bootstrap/Spinner';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { Fragment } from 'react';

function AppCfg(props){

    const cookies = new Cookies();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp } = useContext(LoginContext);    

    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);
    const [ estadoFormPrin, saveEstadoFormPrin ] = useState("");
    const [ estadoFormHijo, saveEstadoFormHijo ] = useState("");

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, saveFormularioHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleDeleteItemPrin, handleSubmitPrin, resetFormPrin, saveFilterRegs, handleSelectFormPrin } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{
                    document.title =confPantalla.config.trad.maintenance.title+" - "+confPantalla.appcfg.title;
                    if( confPantalla.appcfg.panminlvl == null || cookies.get('nivel') <= confPantalla.appcfg.panminlvl ){
                        if( respuesta == 1 ){
                            saveTieneNivel(true);  
                            saveEstadoFormPrin(confPantalla.appcfg.padre.estadoform);
                            saveEstadoFormHijo(confPantalla.appcfg.hijo.estadoForm);                          
                            // ***** Carga inicial de registros *****
                            if( registrosPrin ){
                                if( registrosPrin.length === 0 ){
                                    // Filtrar los registros por la opción de configuración que recibimos como parámetro
                                    if( props.location.state ){ 
                                        if( "cfg" in props.location.state ){
                                            saveFilterRegs(props.location.state.cfg);
                                            cookies.set('filterRegs', props.location.state.cfg, {path: '/'});
                                        }                   
                                    }
                                    saveLoadingTable(true);
                                    regIniciales(saveRegistrosPrin, confPantalla.appcfg.padre.urls);
                                    saveFormularioPrin(confPantalla.appcfg.padre.modelform);
                                    saveFormularioHijo(confPantalla.appcfg.hijo.modelform);                                
                                }
                            }  
                            // Seleccionar el id que nos llega como parámetro por la url
                            if( props.location.state ){
                                if( "idPrin" in props.location.state || "idHijo" in props.location.state ){
                                    handleLastReg(props.location.state.idPrin, props.location.state.idHijo, confPantalla.appcfg.padre.urls, confPantalla.appcfg.hijo.urls, confPantalla.appcfg.padre.tabla, props.location.state.estadoFormPrin, saveEstadoFormPrin, saveEstadoFormHijo);    
                                }                        
                            }                      
                        }
                        else if( respuesta == 0 ){
                            saveTieneNivel(false);
                        }
                    }else{
                        saveTieneNivel(false);
                    }
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                cookies.remove('filterRegs', {path: '/'});
                document.querySelector("body").classList.remove("sidebar-open");
        } 

    },[confPantalla])

    return(
        <div className="App wrapper">
            <div>
                {   loadReg ?
                    <div className="alertFullWhite">
                        <Spinner animation="border" />
                    </div> 
                    :
                    null
                }
                {
                    !loadReg ?
                        <Alert fondo={true} />
                    :
                        <Alert />
                }                                
            </div>     
            {
                confPantalla !== undefined
                ?
                    <Fragment>
                        <Menusup 
                            props = { props }
                        />
                        <Sidebar 
                            props = { props }
                        />
                    </Fragment>
                :
                null
            } 
            <div className="content-wrapper">
                <div className="content-header">
                </div>
                <section className="content">
                {
                        !tieneNivel 
                        ?
                            <PermissionRefused
                                traduction={confPantalla.config.trad.components.permissionrefused} 
                            />
                        :
                            <Fragment>
                            {
                                loadedConf === true && confPantalla !== undefined
                                ?
                                    <Section titulo={ confPantalla.appcfg.section.titulo } icono={ confPantalla.appcfg.section.icono } col={ confPantalla.appcfg.section.col } wiki={ confPantalla.appcfg.section.wiki } >
                                            <Formulario 
                                                        estadoForm= { estadoFormPrin }
                                                        loading=    { loadReg ? true : false }
                                                        btnNew={ confPantalla.appcfg.padre.btnform.new }
                                                        btnSave={ confPantalla.appcfg.padre.btnform.save }
                                                        btnSaveAndNew={ confPantalla.appcfg.padre.btnform.saveandnew }
                                                        btnDelete={ confPantalla.appcfg.padre.btnform.delete }
                                                        btnClone={ confPantalla.appcfg.padre.btnform.clone }
                                                        handleSubmit = { e => handleSubmitPrin(e, confPantalla.appcfg.padre.urls, confPantalla.appcfg.padre.modelform, confPantalla.appcfg.padre.tabla, confPantalla.appcfg.hijo.modelform, confPantalla.appcfg.title) }  
                                                        formulario = { formularioPrin } 
                                                        resetForm = { e => resetFormPrin(confPantalla.appcfg.padre.modelform, true, confPantalla.appcfg.padre.tabla) } 
                                                        handleDeleteItem = { e => handleDeleteItemPrin(undefined, confPantalla.appcfg.padre.urls, confPantalla.appcfg.padre.modelform, confPantalla.appcfg.padre.tabla, confPantalla.appcfg.title) } 
                                            >
                                                <FormRow clases="" >
                                                    <FormInput                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.cod }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.appcfg.padre.controles.cod.id }   
                                                        type=       { confPantalla.appcfg.padre.controles.cod.type }  
                                                        name=       { confPantalla.appcfg.padre.controles.cod.name }   
                                                        label=      { confPantalla.appcfg.padre.controles.cod.label }                                                       
                                                        placeholder={ confPantalla.appcfg.padre.controles.cod.placeholder }    
                                                        col=        { confPantalla.appcfg.padre.controles.cod.col } 
                                                        required=   { confPantalla.appcfg.padre.controles.cod.required }
                                                        readonly=   { formularioPrin.id === "" ? false : true }                                                
                                                        disabled=   { confPantalla.appcfg.padre.controles.cod.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <FormInput                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.des }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.appcfg.padre.controles.des.id }   
                                                        type=       { confPantalla.appcfg.padre.controles.des.type }  
                                                        name=       { confPantalla.appcfg.padre.controles.des.name }   
                                                        label=      { confPantalla.appcfg.padre.controles.des.label }                                                       
                                                        placeholder={ confPantalla.appcfg.padre.controles.des.placeholder }    
                                                        col=        { confPantalla.appcfg.padre.controles.des.col } 
                                                        required=   { confPantalla.appcfg.padre.controles.des.required }
                                                        readonly=   { formularioPrin.id === "" ? false : true }                                                
                                                        disabled=   { confPantalla.appcfg.padre.controles.des.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                </FormRow>
                                                <FormRow clases="" >
                                                    <FormInput                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.lec }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.appcfg.padre.controles.lec.id }   
                                                        type=       { confPantalla.appcfg.padre.controles.lec.type }  
                                                        name=       { confPantalla.appcfg.padre.controles.lec.name }   
                                                        label=      { confPantalla.appcfg.padre.controles.lec.label }                                                       
                                                        placeholder={ confPantalla.appcfg.padre.controles.lec.placeholder }    
                                                        col=        { confPantalla.appcfg.padre.controles.lec.col } 
                                                        required=   { confPantalla.appcfg.padre.controles.lec.required }
                                                        readonly=   { formularioPrin.id === "" ? false : true }                                                
                                                        disabled=   { confPantalla.appcfg.padre.controles.lec.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <FormInput                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.esc }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.appcfg.padre.controles.esc.id }   
                                                        type=       { confPantalla.appcfg.padre.controles.esc.type }  
                                                        name=       { confPantalla.appcfg.padre.controles.esc.name }   
                                                        label=      { confPantalla.appcfg.padre.controles.esc.label }                                                       
                                                        placeholder={ confPantalla.appcfg.padre.controles.esc.placeholder }    
                                                        col=        { confPantalla.appcfg.padre.controles.esc.col } 
                                                        required=   { confPantalla.appcfg.padre.controles.esc.required }
                                                        readonly=   { formularioPrin.id === "" ? false : true }                                                
                                                        disabled=   { confPantalla.appcfg.padre.controles.esc.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                </FormRow>
                                                <FormRow>
                                                    <FormInput                                                        
                                                        estadoForm= { estadoFormPrin }
                                                        value=      { formularioPrin.val }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.appcfg.padre.controles.val.id }   
                                                        type=       { confPantalla.appcfg.padre.controles.val.type }  
                                                        name=       { confPantalla.appcfg.padre.controles.val.name }   
                                                        label=      { confPantalla.appcfg.padre.controles.val.label }                                                       
                                                        placeholder={ confPantalla.appcfg.padre.controles.val.placeholder }    
                                                        col=        { confPantalla.appcfg.padre.controles.val.col } 
                                                        required=   { confPantalla.appcfg.padre.controles.val.required }
                                                        readonly=   { confPantalla.appcfg.padre.controles.val.readonly }                                                
                                                        disabled=   { confPantalla.appcfg.padre.controles.val.disabled }
                                                        change=     { e => handleInputForm(e, formularioPrin, saveFormularioPrin) }
                                                        onBlur=     {null} 
                                                    />
                                                    <MiSelect  
                                                        estadoForm=  { estadoFormPrin }
                                                        val=         { formularioPrin.idusu  }   
                                                        loading=     { loadReg ? true : false }
                                                        id=          { confPantalla.appcfg.padre.controles.idusu.id }    
                                                        name=        { confPantalla.appcfg.padre.controles.idusu.name }   
                                                        label=       { confPantalla.appcfg.padre.controles.idusu.label }    
                                                        placeholder= { confPantalla.appcfg.padre.controles.idusu.placeholder }
                                                        col=         { confPantalla.appcfg.padre.controles.idusu.col } 
                                                        isClearable= { confPantalla.appcfg.padre.controles.idusu.isclearable }
                                                        isSearchable={ confPantalla.appcfg.padre.controles.idusu.issearchable }
                                                        dataType=    { confPantalla.appcfg.padre.controles.idusu.datatype }
                                                        data=        { confPantalla.appcfg.padre.controles.idusu.data }
                                                        required=    { confPantalla.appcfg.padre.controles.idusu.required } 
                                                        disabled=    { confPantalla.appcfg.padre.controles.idusu.disabled }
                                                        readOnly =   { confPantalla.appcfg.padre.controles.idusu.readonly }
                                                        disablethisid={confPantalla.appcfg.padre.controles.idusu.disablethisid}
                                                        formulario = { formularioPrin }   
                                                        change=      { handleSelectFormPrin }                                                    
                                                    />  
                                                </FormRow>
                                            </Formulario>                                            
                                            <MiDataTable
                                                formulario={formularioPrin} 
                                                estadoForm= { estadoFormPrin }
                                                id={"appcfg"}
                                                columnas={confPantalla.appcfg.padre.datatable.columns}
                                                data={registrosPrin}
                                                buttons={confPantalla.appcfg.padre.datatable.buttons}
                                                ordering={confPantalla.appcfg.padre.datatable.ordering}
                                                order={confPantalla.appcfg.padre.datatable.order}
                                                searching={confPantalla.appcfg.padre.datatable.searching}
                                                paging={confPantalla.appcfg.padre.datatable.paging}
                                                select={confPantalla.appcfg.padre.datatable.select}
                                                pagelength={confPantalla.appcfg.padre.datatable.pagelength}
                                                handleEditReg= { e => handleEditRegPrin(e, confPantalla.appcfg.padre.urls, confPantalla.appcfg.hijo.urls, confPantalla.appcfg.padreshijos, confPantalla.appcfg.hijo.modelform, confPantalla.appcfg.padre.tabla) }
                                                handleDeleteItem={ e => handleDeleteItemPrin(e, confPantalla.appcfg.padre.urls, confPantalla.appcfg.padre.modelform, confPantalla.appcfg.padre.tabla, confPantalla.appcfg.title) }
                                                saveLoadingTable={ saveLoadingTable }
                                                loadingTable={loadingTable}
                                                columnactions={confPantalla.appcfg.padre.datatable.columnactions.title}
                                                traduction={confPantalla.config.trad.components.datatable}
                                            />        
                                    </Section>
                                :
                                    <LoadingPage />
                            }
                            </Fragment>
                }
                </section>
            </div>
        <Footer />        
        </div>

    );

}
export default AppCfg